import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { injectIntl } from 'react-intl';
import { Modal, ModalButtons, Button, TextField, Checkbox, Label } from 'mw-style-react';
import { MODIFY_WEBHOOK } from 'constants';
import connect from 'react-redux/es/connect/connect';
import AppUtils from '../../../../utils/utils';
import TextButton from '../../../TextButton';
import sModal from '../../Modal.scss';

const pushKeysToCheckbox = (actions = [], allActions = []) => {
  const res = {};
  actions.forEach(action => {
    const id = allActions.find(act => act.values.some(el => el === action))?.id;
    res[id] = true;
  });

  return res;
};

class ModifyWebhook extends PureComponent {
  constructor(props) {
    super(props);
    const { data } = props;

    this.state = {
      isSubmit: false,
      url: data.webhook.url,
      checkBoxes: pushKeysToCheckbox(data.webhook.actions, data.allActions),
      error: false
    };
  }

  handleSubmit() {
    const { dispatch, onClose, data } = this.props;
    const { ext_id: workspaceId } = data || {};
    const { url, checkBoxes } = this.state;

    const actions = [];

    Object.keys(checkBoxes).forEach(c => {
      if (checkBoxes[c]) {
        const action = data.allActions.find(a => a.id === c);
        actions.push(action ? action.values : c);
      }
    });

    if (!url || !AppUtils.validateUrl(url)) {
      this.setState({
        error: true
      });
      return;
    }

    this.setState({
      isSubmit: true
    });

    dispatch({
      type: MODIFY_WEBHOOK.REQUEST,
      payload: {
        params: { workspaceId, webhookId: data.webhook.id },
        body: {
          url,
          actions: actions.flat(),
          status: data.webhook.status
        },
        callback: result => {
          if (result === 'error') {
            this.setState({
              isSubmit: false
            });
          } else {
            if (data.callbacks && data.callbacks.onSuccessSubmit) {
              data.callbacks.onSuccessSubmit();
            }
            onClose();
          }
        }
      }
    });
  }

  handleOnChangeUrl(e) {
    const { value } = e;
    this.setState({
      url: value,
      error: !AppUtils.validateUrl(value)
    });
  }

  handleOnChangeCheckbox(id) {
    this.setState(prev => ({
      ...prev,
      checkBoxes: {
        ...prev.checkBoxes,
        [id]: !prev.checkBoxes[id]
      }
    }));
  }

  render() {
    const { visibility, onClose, data } = this.props;

    const { url, isSubmit, error, checkBoxes = {} } = this.state;

    const anyTrue = Object.keys(checkBoxes).some(k => checkBoxes[k]);

    return (
      <Modal visibility={visibility} onClose={onClose} styleName="sModal.modal__workspace">
        <div styleName="sModal.modal__header">
          <div styleName="sModal.modal__header__close">
            <TextButton title="Cancel" onClick={onClose} />
          </div>
          <div styleName="sModal.modal__header__title">Modify webhook</div>
        </div>
        <div styleName="sModal.modal__body" style={{ paddingTop: '20px', overflow: 'hidden' }}>
          <div style={{ overflowY: 'auto', overflowX: 'hidden', marginBottom: '20px' }}>
            <div>
              <div styleName="sModal.modal__input__label">URL</div>
              <TextField
                styleName="sModal.modal__input"
                value={url}
                onChange={this.handleOnChangeUrl.bind(this)}
                placeholder="Webhook URL"
                length={255}
                bordered
                error={error}
                id="url"
                visibility={isSubmit ? 'disabled' : 'visible'}
              />
              <div
                styleName="sModal.modal__input__label__description"
                style={{ marginTop: -16, marginBottom: 16 }}
              >
                Actions from your workspace will be performed on this link
              </div>
            </div>
            <div>
              <div styleName="sModal.modal__input__label">
                Actions{' '}
                <span styleName="sModal.modal__input__label__description">
                  (Choose 1 or more actions which you want to track)
                </span>
              </div>
              <div styleName="sModal.modal__checkboxGroup is-align-top" style={{ margin: 0 }}>
                <ul>
                  {data.allActions.map(action => {
                    return (
                      <li key={action.id}>
                        <Checkbox
                          value={checkBoxes[action.id]}
                          onChange={() => this.handleOnChangeCheckbox(action.id)}
                          styleName="sModal.modal__checkboxGroup__checkbox"
                        />
                        <div styleName="sModal.modal__input__label__ellipsis">
                          <Label fontWeight="semibold" value={action.name} />
                          <br />
                          <span styleName="sModal.modal__input__label__description sModal.modal__input__label__description--full">
                            {action.description}
                          </span>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
          <ModalButtons styleName="sModal.modal__buttons">
            <Button
              label="Modify"
              size="medium"
              onClick={this.handleSubmit.bind(this)}
              visibility={isSubmit || !anyTrue || this.state.error ? 'disabled' : 'visible'}
              disabled={isSubmit || !anyTrue || this.state.error}
              styleName="sModal.modal__btn sModal.wide"
            />
          </ModalButtons>
        </div>
      </Modal>
    );
  }
}

ModifyWebhook.propTypes = {
  visibility: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  data: PropTypes.object,
  dispatch: PropTypes.func,
  actions: PropTypes.array
};

const mapStateToProps = null;

export default injectIntl(connect(mapStateToProps)(ModifyWebhook));
