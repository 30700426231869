/* eslint-disable react/forbid-prop-types */
import React from 'react';
import cn from 'classnames';
import { Icon } from 'mw-style-react';
import PropTypes from 'prop-types';

import './TextButton.scss';

function TextButton({ onClick, title, style = {}, disabled, type, icon }) {
  const handleClick = e => {
    if (disabled) return;
    onClick(e);
  };
  return (
    <button
      styleName={cn('textButton', {
        secondary: type === 'secondary',
        inline: type === 'inline'
      })}
      onClick={handleClick}
      type="button"
      style={style}
      disabled={disabled}
    >
      {title}
      {icon && <Icon type={icon} />}
    </button>
  );
}

TextButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  style: PropTypes.object,
  title: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  icon: PropTypes.string
};

export default TextButton;
