import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Avatar, Badge } from 'mw-style-react';
import { getContrastColor } from '../../utils/helperFunctions';
import './UserPanel.scss';

function UserPanel({ data, userId, isSingleLine = false }) {
  if (!data) return <span>&mdash;</span>;

  if (`${data.id}` === `${userId}`) return <span>Me</span>;

  if (data.name) {
    const renderBadge = () => {
      if (data.status && data.status.value === 'block') {
        return <Badge bgColor="#D34437" size="small" />;
      }
      return null;
    };
    return (
      <span styleName="UserPanel">
        <Avatar
          styleName="UserPanel__user-photo"
          size="small"
          src={data.photo}
          bgColor={data.color}
          label={data.name}
          textColor={getContrastColor(data.color)}
          badge={renderBadge()}
        />
        <span
          styleName={cn('UserPanel__user-name', {
            'UserPanel__user-name--single-line': isSingleLine
          })}
        >
          {data.name}
        </span>
      </span>
    );
  }
  return <span>&mdash;</span>;
}

UserPanel.propTypes = {
  data: PropTypes.object,
  userId: PropTypes.string,
  isSingleLine: PropTypes.bool
};

export default UserPanel;
