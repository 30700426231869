/* eslint-disable max-len */
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import connect from 'react-redux/es/connect/connect';
import { intlShape, injectIntl } from 'react-intl';
import {
  Modal,
  ModalButtons,
  Avatar,
  Button,
  TextField,
  ProgressBar,
  Checkbox,
  Label,
  Select,
  MenuItem
} from 'mw-style-react';
import cn from 'classnames';
import {
  SEARCH_USERS,
  GET_USERS_SUGGESTION,
  GET_AVAILABLE_ROLES,
  DEBOUNCE_MS,
  ADD_OWNER_REQUEST
} from 'constants';
import InfiniteScroll from 'react-infinite-scroll-component';
import AppUtils from '../../../../utils/utils';
import TextButton from '../../../TextButton';
import IconButton from '../../../IconButton';
import mes from './intl';
import { getContrastColor } from '../../../../utils/helperFunctions';
import sModal from '../../Modal.scss';

class ChangeWorkspaceOwnership extends PureComponent {
  constructor(props) {
    super(props);
    const { data, dispatch } = props;

    this.state = {
      isSubmit: false,
      searchName: '',
      userList: [],
      selectedUsers: [],
      leave: false,
      focus: false,
      hasMore: true,
      offset: 0,

      role: null,
      loading: true,

      searchName2: '',
      selectedUsers2: [],
      focus2: false,
      searchLoading: false
    };

    this.timer = null;
    this.listRef = React.createRef();
    this.wrapperRef = React.createRef();
    this.wrapperRef2 = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);

    this.getSuggestion(data);

    dispatch({
      type: GET_AVAILABLE_ROLES.REQUEST,
      payload: {
        params: {
          workspaceId: data.ext_id || data.workspaceId
        },
        callback: result => {
          if (result !== 'error') {
            this.setState({
              loading: false
            });
          }
        }
      }
    });
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  getSuggestion(data) {
    const { dispatch } = this.props;
    const { searchName } = this.state;

    dispatch({
      type: GET_USERS_SUGGESTION.REQUEST,
      payload: {
        params: {
          workspaceId: data.id,
          limit: 20,
          offset: 0,
          search: searchName,
          merge: true
        },
        callback: (result, data, hasMore) => {
          if (result === 'success' && data) {
            this.setState(prev => ({
              ...prev,
              userList: data,
              offset: 0,
              hasMore,
              searchLoading: false
            }));
          }
        }
      }
    });
  }

  /**
   * Alert if clicked on outside of element
   */
  handleClickOutside(event) {
    const { focus, focus2 } = this.state;
    if (
      focus &&
      this.wrapperRef &&
      this.wrapperRef.current &&
      !this.wrapperRef.current.contains(event.target)
    ) {
      this.setState({
        focus: false
      });
    }

    if (
      focus2 &&
      this.wrapperRef2 &&
      this.wrapperRef2.current &&
      !this.wrapperRef2.current.contains(event.target)
    ) {
      this.setState({
        focus2: false
      });
    }
  }

  handleOnChangeCheckbox() {
    this.setState(prev => ({
      leave: !prev.leave
    }));
  }

  handleOnChangeRoles(e) {
    const { value } = e;
    this.setState({
      role: value
    });
  }

  fetchMore() {
    const { dispatch, data } = this.props;
    const { offset, searchName } = this.state;

    dispatch({
      type: GET_USERS_SUGGESTION.REQUEST,
      payload: {
        params: {
          workspaceId: data.id,
          limit: 20,
          offset: offset + 20,
          search: searchName,
          merge: true
        },
        body: {},
        callback: (result, data, hasMore) => {
          if (result === 'success' && data) {
            this.setState(prev => ({
              ...prev,
              userList: [...prev.userList, ...data],
              offset: prev.offset + 20,
              hasMore
            }));
          }
        }
      }
    });
  }

  // Ф-ция локализации
  i(mesId, values) {
    return AppUtils.getMes(this.context)(mesId, values);
  }

  setFocus() {
    this.setState({
      focus: true,
      focus2: false
    });
  }

  setFocus2() {
    this.setState({
      focus2: true,
      focus: false
    });
  }

  addToActive(user) {
    const {
      auth: { user_id: userId },
      data
    } = this.props;
    if (userId !== user.id) {
      this.setState(state => ({
        ...state,
        searchName: '',
        focus: false,
        selectedUsers: [user]
      }));

      this.getSuggestion(data);
    }
  }

  addToActive2(user) {
    const {
      auth: { user_id: userId },
      data
    } = this.props;

    if (userId !== user.id) {
      this.setState(
        prevState => {
          const userAlreadySelected = prevState.selectedUsers2.some(su => su.id === user.id);

          if (!userAlreadySelected) {
            return {
              ...prevState,
              searchName2: '',
              focus2: false,
              selectedUsers2: [...prevState.selectedUsers2, user]
            };
          }

          return {
            ...prevState,
            searchName2: '',
            focus2: false
          };
        },
        () => {
          this.getSuggestion(data);
        }
      );
    }
  }

  handleRemoveItem(item) {
    const { id } = item || {};

    this.setState(state => ({
      ...state,
      selectedUsers: state.selectedUsers.filter(u => u.id !== id)
    }));
  }

  handleRemoveItem2(item) {
    const { id } = item || {};

    this.setState(state => ({
      ...state,
      selectedUsers2: state.selectedUsers2.filter(u => u.id !== id)
    }));
  }

  handleOnChangeSearch(e) {
    const { dispatch, data } = this.props;
    const { id } = data || {};
    const { value } = e;
    this.setState({
      searchName: value,
      offset: 0,
      hasMore: true,
      searchLoading: true
    });

    clearTimeout(this.timer);
    if (this.listRef.current) {
      this.listRef.current.scrollTop = 0;
    }
    this.timer = setTimeout(() => {
      if (value.length < 2) {
        this.getSuggestion(data);
      }

      if (value.length > 1) {
        dispatch({
          type: SEARCH_USERS.REQUEST,
          payload: {
            params: {
              workspaceId: id,
              query: value,
              limit: 20
            },
            callback: (result, data, hasMore) => {
              if (result === 'success' && data) {
                this.setState({
                  userList: data,
                  searchLoading: false,
                  hasMore
                });
              }
            }
          }
        });
      } else {
        this.setState({
          userList: []
        });
      }
    }, DEBOUNCE_MS);
  }

  handleOnChangeSearch2(e) {
    const { dispatch, data } = this.props;
    const { ext_id: workspaceId } = data || {};
    const { value } = e;
    this.setState({
      searchName2: value
    });

    if (value.length < 3) {
      this.getSuggestion(data);
    }

    if (value.length > 2) {
      dispatch({
        type: SEARCH_USERS.REQUEST,
        payload: {
          params: {
            workspaceId,
            query: value,
            limit: 20
          },
          callback: (result, data) => {
            if (result === 'success' && data) {
              this.setState({
                userList: data
              });
            }
          }
        }
      });
    } else {
      this.setState({
        userList: []
      });
    }
  }

  handleSubmit() {
    const {
      dispatch,
      onClose,
      data: { ext_id: extId, callback }
    } = this.props;
    const { selectedUsers, selectedUsers2, leave, role } = this.state;
    const ownerId = selectedUsers.length ? selectedUsers[0].id : undefined;
    const propOwnerIds = selectedUsers2.map(s => s.id);

    this.setState({
      isSubmit: true
    });

    if (leave) {
      dispatch({
        type: ADD_OWNER_REQUEST.REQUEST,
        payload: {
          params: { workspaceId: extId },
          body: {
            obj_owner_ids: propOwnerIds,
            new_owner_id: ownerId,
            workspace_id: extId
          },
          callback: result => {
            if (result === 'error') {
              this.setState({
                isSubmit: false
              });
            } else {
              onClose();
              if (callback) {
                callback();
              }
            }
          }
        }
      });
    } else {
      dispatch({
        type: ADD_OWNER_REQUEST.REQUEST,
        payload: {
          params: { workspaceId: extId },
          body: {
            to_role_id: Number(role),
            new_owner_id: ownerId,
            workspace_id: extId
          },
          callback: result => {
            if (result === 'error') {
              this.setState({
                isSubmit: false
              });
            } else {
              onClose();
              if (callback) {
                callback();
              }
            }
          }
        }
      });
    }
  }

  render() {
    const { visibility, onClose, roles } = this.props;
    const { list = [] } = roles || {};

    const {
      isSubmit,
      searchName,
      userList,
      selectedUsers,
      focus,
      hasMore,
      leave,
      loading,
      role,
      searchName2,
      selectedUsers2,
      focus2,
      searchLoading
    } = this.state;
    const {
      data: { name },
      auth
    } = this.props;
    const { user_id: userId } = auth || {};
    const filteredUserList = userList.filter(
      u => !selectedUsers.find(s => s.id === u.id) && u.status !== 'blocked'
    );
    const filteredUserList2 = userList.filter(u => !selectedUsers2.some(el => el.id === u.id));

    return (
      <Modal
        visibility={visibility}
        onClose={onClose}
        styleName="sModal.modal__workspace sModal.modal__overflowVisible"
      >
        <div styleName="sModal.modal__header">
          <div styleName="sModal.modal__header__close">
            <TextButton title="Cancel" onClick={onClose} />
          </div>
          <div styleName="sModal.modal__header__title">{this.i(mes.changeWorkspaceOwnership)}</div>
        </div>
        <div styleName="sModal.modal__body">
          <div styleName="sModal.modal__section">
            <div styleName="sModal.modal__description">
              Select the user to transfer the <strong>{name}</strong> workspace ownership to. <br />
            </div>
            <div style={{ marginTop: '20px', position: 'relative' }}>
              <div styleName="sModal.modal__input__label">Select user</div>
              <TextField
                styleName="sModal.modal__input"
                value={searchName}
                onChange={this.handleOnChangeSearch.bind(this)}
                placeholder={this.i(mes.changeWorkspaceOwnershipLabel)}
                leftIcon="search"
                length={255}
                bordered
                id="searchName"
                visibility={isSubmit ? 'disabled' : 'visible'}
                onFocus={this.setFocus.bind(this)}
              />
              {focus && filteredUserList.length ? (
                <div styleName="sModal.modal__input__searchItems" ref={this.wrapperRef}>
                  <ul id="scrollableModal" ref={this.listRef}>
                    <InfiniteScroll
                      dataLength={filteredUserList.length}
                      next={this.fetchMore.bind(this)}
                      hasMore={hasMore}
                      style={{ overflow: 'visible' }}
                      loader={
                        <div styleName="modal__workspace__suggestion__loader">
                          <ProgressBar />
                        </div>
                      }
                      scrollableTarget="scrollableModal"
                    >
                      {searchLoading && (
                        <div styleName="modal__workspace__suggestion__list-loader">
                          <ProgressBar />
                        </div>
                      )}
                      {filteredUserList.map(u => (
                        <li
                          key={u.id}
                          styleName={cn({
                            'sModal.listItem__disabled': u.id === userId
                          })}
                        >
                          <div
                            styleName="sModal.modal__input__searchItemControl"
                            role="button"
                            tabIndex={0}
                            onClick={() => this.addToActive(u)}
                            onKeyDown={() => this.addToActive(u)}
                          >
                            <Avatar
                              styleName="modal__small__avatar"
                              size="small"
                              src={u.photo}
                              bgColor={u.color}
                              label={u.name}
                              textColor={getContrastColor(u.color)}
                            />
                            <span>{u.name}</span>
                          </div>
                        </li>
                      ))}
                    </InfiniteScroll>
                  </ul>
                </div>
              ) : null}

              {!filteredUserList.length && searchName.length > 1 ? (
                <div styleName="sModal.modal__input__searchItems" ref={this.wrapperRef}>
                  {searchLoading ? (
                    <div styleName="modal__workspace__suggestion__list-loader is-solid">
                      <ProgressBar />
                    </div>
                  ) : (
                    <div styleName="modal__workspace__suggestion__list-no-data">
                      No results for &quot;{searchName}&quot;
                    </div>
                  )}
                </div>
              ) : null}
            </div>
            <div>
              {selectedUsers.length ? (
                <div styleName="sModal.modal__list__items">
                  <ul>
                    {selectedUsers.map(u => (
                      <li key={u.id}>
                        <div styleName="sModal.modal__list__items__element">
                          <div styleName="sModal.modal__list__items__element__group">
                            <Avatar
                              styleName="modal__small__avatar"
                              size="small"
                              src={u.photo}
                              bgColor={u.color}
                              label={u.name}
                              textColor={getContrastColor(u.color)}
                            />
                            <span>{u.name}</span>
                          </div>
                          <IconButton icon="close" onClick={() => this.handleRemoveItem(u)} />
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              ) : null}
            </div>
            <div>
              <div style={{ marginBottom: '16px' }}>
                <Checkbox
                  value={leave}
                  onChange={() => this.handleOnChangeCheckbox()}
                  styleName="sModal.modal__checkboxGroup__checkbox"
                >
                  <div styleName="sModal.modal__input__label__ellipsis">
                    <Label value="Leave the workspace after the transfer" />
                  </div>
                </Checkbox>
              </div>
            </div>
            {leave ? (
              <div styleName="sModal.modal__section" style={{ marginTop: 0 }}>
                <div style={{ position: 'relative' }}>
                  <div styleName="sModal.modal__input__label">
                    Transfer the workspace objects’ permissions to the selected user:
                  </div>
                  <TextField
                    styleName="sModal.modal__input"
                    value={searchName2}
                    onChange={this.handleOnChangeSearch2.bind(this)}
                    placeholder="Search by name or email"
                    leftIcon="search"
                    length={255}
                    bordered
                    id="searchName"
                    visibility={isSubmit ? 'disabled' : 'visible'}
                    onFocus={this.setFocus2.bind(this)}
                  />
                  {focus2 && filteredUserList2.length ? (
                    <div styleName="sModal.modal__input__searchItems" ref={this.wrapperRef2}>
                      <ul id="scrollableModal">
                        <InfiniteScroll
                          dataLength={filteredUserList2.length}
                          next={this.fetchMore.bind(this)}
                          hasMore={hasMore && !searchName2.length}
                          loader={
                            <div styleName="modal__workspace__suggestion__loader">
                              <ProgressBar />
                            </div>
                          }
                          scrollableTarget="scrollableModal"
                        >
                          {filteredUserList2.map(u => (
                            <li
                              key={u.id}
                              styleName={cn({
                                'sModal.listItem__disabled': u.id === userId
                              })}
                            >
                              <div
                                styleName="sModal.modal__input__searchItemControl"
                                role="button"
                                tabIndex={0}
                                onClick={() => this.addToActive2(u)}
                                onKeyDown={() => this.addToActive2(u)}
                              >
                                <Avatar
                                  styleName="modal__small__avatar"
                                  size="small"
                                  src={u.photo}
                                  bgColor={u.color}
                                  label={u.name}
                                  textColor={getContrastColor(u.color)}
                                />
                                <span>{u.name}</span>
                              </div>
                            </li>
                          ))}
                        </InfiniteScroll>
                      </ul>
                    </div>
                  ) : null}
                </div>
                <div>
                  <div styleName="sModal.modal__list__items">
                    {selectedUsers2.length ? (
                      <ul>
                        {selectedUsers2.map(u => (
                          <li key={u.id}>
                            <div styleName="sModal.modal__list__items__element">
                              <div styleName="sModal.modal__list__items__element__group">
                                <Avatar
                                  styleName="modal__small__avatar"
                                  size="small"
                                  src={u.photo}
                                  bgColor={u.color}
                                  label={u.name}
                                  textColor={getContrastColor(u.color)}
                                />
                                <span>{u.name}</span>
                              </div>
                              <IconButton icon="close" onClick={() => this.handleRemoveItem2(u)} />
                            </div>
                          </li>
                        ))}
                      </ul>
                    ) : null}
                  </div>
                </div>
              </div>
            ) : (
              <div>
                <div styleName="sModal.modal__select__label">
                  {this.i(mes.changeOwnershipRoleLabel)}
                </div>
                <Select
                  styleName="sModal.modal__select sModal.modal__select__short"
                  value={role}
                  onChange={this.handleOnChangeRoles.bind(this)}
                  placeholder={this.i(mes.changeOwnershipRolePlaceholder)}
                  length={255}
                  bordered
                  id="role"
                  visibility={isSubmit || leave ? 'disabled' : 'visible'}
                >
                  {list && list.length && !loading
                    ? list.map(item => <MenuItem label={item.name} value={String(item.id)} />)
                    : null}
                </Select>
              </div>
            )}
          </div>
          <ModalButtons styleName="sModal.modal__buttons">
            <Button
              label={this.i(mes.btnChangeWorkspaceOwnership)}
              size="medium"
              onClick={this.handleSubmit.bind(this)}
              styleName={cn('sModal.modal__btn sModal.wide')}
              visibility={
                isSubmit ||
                !selectedUsers.length ||
                (leave && !selectedUsers2.length) ||
                (!leave && !role)
                  ? 'disabled'
                  : 'visible'
              }
              disabled={
                isSubmit ||
                !selectedUsers.length ||
                (leave && !selectedUsers2.length) ||
                (!leave && !role)
              }
            />
          </ModalButtons>
        </div>
      </Modal>
    );
  }
}

ChangeWorkspaceOwnership.propTypes = {
  visibility: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  roles: PropTypes.object.isRequired
};

ChangeWorkspaceOwnership.contextTypes = {
  intl: intlShape
};

const mapStateToProps = ({ roles }) => ({
  roles
});

export default injectIntl(connect(mapStateToProps)(ChangeWorkspaceOwnership));
