import { defineMessages } from 'react-intl';
import globalIntl from 'globalIntl'; // eslint-disable-line import/no-unresolved

const m = defineMessages({
  changeWorkspaceOwnership: {
    id: 'changeWorkspaceOwnership',
    defaultMessage: 'Transfer workspace ownership'
  },
  btnChangeWorkspaceOwnership: {
    id: 'btnChangeWorkspaceOwnership',
    defaultMessage: 'Transfer ownership'
  },
  changeWorkspaceOwnershipLabel: {
    id: 'changeWorkspaceOwnershipLabel',
    defaultMessage: 'User'
  },
  changeOwnershipRoleLabel: {
    id: 'changeOwnershipRoleLabel',
    defaultMessage: 'Select your new role'
  },
  changeOwnershipRolePlaceholder: {
    id: 'changeOwnershipRolePlaceholder',
    defaultMessage: 'Role'
  }
});

export default { ...globalIntl, ...m };
