import { SET_MODAL, DEL_MODAL } from 'constants';

// ------------------------------------
// Reducer
// ------------------------------------

export default function appAuthReducer(state = null, action) {
  switch (action.type) {
    case SET_MODAL:
      return { ...action.payload };
    case DEL_MODAL:
      return null;
    default:
      return state;
  }
}
