/* eslint-disable max-len */
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { intlShape, injectIntl } from 'react-intl';
import { Modal, ModalButtons, ModalContent, Button } from 'mw-style-react';
import { MODIFY_ENV_USER } from 'constants';
import cn from 'classnames';
import AppUtils from '../../../../utils/utils';
import TextButton from '../../../TextButton';
import mes from './intl';
import sModal from '../../Modal.scss'; // eslint-disable-line no-unused-vars

class BlockEnvUser extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isSubmit: false
    };
  }

  // Ф-ция локализации
  i(mesId, values) {
    return AppUtils.getMes(this.context)(mesId, values);
  }

  handleSubmit() {
    const { dispatch, onClose, data } = this.props;

    this.setState({
      isSubmit: true
    });

    dispatch({
      type: MODIFY_ENV_USER.REQUEST,
      payload: {
        body: { status: 'blocked' },
        params: {
          userId: data.id
        },
        callback: () => {
          onClose();
          if (data && data.callback) {
            data.callback();
          }
        }
      }
    });
  }

  render() {
    const { visibility, onClose } = this.props;

    const { isSubmit } = this.state;
    const {
      data: { name }
    } = this.props;

    return (
      <Modal visibility={visibility} onClose={onClose} styleName="sModal.modal__workspace">
        <div styleName="sModal.modal__header">
          <div styleName="sModal.modal__header__close">
            <TextButton title="Cancel" onClick={onClose} />
          </div>
          <div styleName="sModal.modal__header__title">{this.i(mes.blockUser)}</div>
        </div>
        <div styleName="sModal.modal__body">
          <ModalContent styleName="sModal.modal__content">
            <div>
              <div styleName="sModal.modal__description">
                The user <strong>{name}</strong> will be blocked in all workspaces and their paid
                license will be canceled.
              </div>
            </div>
          </ModalContent>
          <ModalButtons styleName="sModal.modal__buttons">
            <Button
              label={this.i(mes.btnBlockUser)}
              size="medium"
              onClick={this.handleSubmit.bind(this)}
              visibility={isSubmit ? 'disabled' : 'visible'}
              disabled={isSubmit}
              styleName={cn('sModal.modal__btn', 'sModal.red', 'sModal.wide', {
                red__disabled: isSubmit
              })}
            />
          </ModalButtons>
        </div>
      </Modal>
    );
  }
}

BlockEnvUser.propTypes = {
  visibility: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  data: PropTypes.object,
  dispatch: PropTypes.func
};

BlockEnvUser.contextTypes = {
  intl: intlShape
};

export default injectIntl(BlockEnvUser);
