import { defineMessages } from 'react-intl';
import globalIntl from 'globalIntl'; // eslint-disable-line import/no-unresolved

const m = defineMessages({
  changeGroupOwnership: {
    id: 'changeGroupOwnership',
    defaultMessage: 'Transfer group ownership'
  },
  btnChangeGroupOwnership: {
    id: 'btnChangeGroupOwnership',
    defaultMessage: 'Transfer ownership'
  },
  changeGroupOwnershipLabel: {
    id: 'changeGroupOwnershipLabel',
    defaultMessage: 'Search by name or email'
  }
});

export default { ...globalIntl, ...m };
