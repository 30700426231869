import { Utils } from 'mw-style-react';

// app actions
export const CONFIG = Utils.createRequestTypes('CONFIG');
export const CLIENT = Utils.createRequestTypes('CLIENT');
export const SET_THEME = Utils.createRequestTypes('SET_THEME');
export const SHOW_NOTIFY = Utils.createRequestTypes('SHOW_NOTIFY');
export const HIDE_NOTIFY = Utils.createRequestTypes('HIDE_NOTIFY');

// ENTER
export const LOGIN = Utils.createRequestTypes('LOGIN');
export const LOGIN_VERIFY_EMAIL = Utils.createRequestTypes('LOGIN_VERIFY_EMAIL');
export const AUTH_CLEAR = 'AUTH_CLEAR';
export const SIGN_UP = Utils.createRequestTypes('SIGN_UP');
export const ADD_EMAIL = Utils.createRequestTypes('ADD_EMAIL');
export const PASSWORD_RECOVER = Utils.createRequestTypes('PASSWORD_RECOVER');
export const PASSWORD_CONFIRM = Utils.createRequestTypes('PASSWORD_CONFIRM');
export const SIGN_IN_CAPTCHA = Utils.createRequestTypes('SIGN_IN_CAPTCHA');
export const GET_SCOPE = Utils.createRequestTypes('GET_SCOPE');
export const SAVE_DETAILS = Utils.createRequestTypes('SAVE_DETAILS');

export const TEST = Utils.createRequestTypes('TEST');

// User
export const ME = Utils.createRequestTypes('ME');
export const AUTH = Utils.createRequestTypes('AUTH');
export const LOGOUT = Utils.createRequestTypes('LOGOUT');
export const GET_CLIENTS = Utils.createRequestTypes('GET_CLIENTS');
export const SAVE_PROFILE = Utils.createRequestTypes('SAVE_PROFILE');
export const GET_QR_CODE = Utils.createRequestTypes('GET_QR_CODE');
export const SEND_QR_CODE = Utils.createRequestTypes('SEND_QR_CODEv');
export const OFF_2FA = Utils.createRequestTypes('OFF_2FA');

export const AUTH_USER_INFO = Utils.createRequestTypes('AUTH_USER_INFO');
export const AUTH_USER_ROLE_PERMS = Utils.createRequestTypes('AUTH_USER_ROLE_PERMS');

// Application
export const GET_APPLICATIONS = Utils.createRequestTypes('GET_APPLICATIONS');
export const SET_APPLICATION = Utils.createRequestTypes('SET_APPLICATION');
export const GET_APPLICATION = Utils.createRequestTypes('GET_APPLICATION');
export const DEL_APPLICATION = Utils.createRequestTypes('DEL_APPLICATION');
export const UPDATE_APPLICATION = Utils.createRequestTypes('UPDATE_APPLICATION');
export const RESET_CLIENT_SECRET = Utils.createRequestTypes('RESET_CLIENT_SECRET');
export const CODE_AUTH = Utils.createRequestTypes('CODE_AUTH');

// Organization
export const GET_ORGANIZATION_DETAILS = Utils.createRequestTypes('GET_ORGANIZATION_DETAILS');
export const GET_ORGANIZATION_IDP = Utils.createRequestTypes('GET_ORGANIZATION_IDP');
export const CREATE_ORGANIZATION_IDP = Utils.createRequestTypes('CREATE_ORGANIZATION_IDP');
export const UPDATE_ORGANIZATION_IDP = Utils.createRequestTypes('UPDATE_ORGANIZATION_IDP');

export const GET_ORGANIZATION_IDP_MAPS = Utils.createRequestTypes('GET_ORGANIZATION_IDP_MAPS');
export const CREATE_ORGANIZATION_IDP_MAP = Utils.createRequestTypes('CREATE_ORGANIZATION_IDP_MAP');
export const UPDATE_ORGANIZATION_IDP_MAP = Utils.createRequestTypes('UPDATE_ORGANIZATION_IDP_MAP');
export const DELETE_ORGANIZATION_IDP_MAP = Utils.createRequestTypes('DELETE_ORGANIZATION_IDP_MAP');

// Licenses
export const GET_LICENSES = Utils.createRequestTypes('GET_LICENSES');
export const GET_USER_LINCESES = Utils.createRequestTypes('GET_USER_LINCESES');
export const SET_USER_LINCESE = Utils.createRequestTypes('SET_USER_LINCESE');
export const CHECK_USER_LINCESE = Utils.createRequestTypes('CHECK_USER_LINCESE');
export const GET_LINCESE_ATTRIBUTES = Utils.createRequestTypes('GET_LINCESE_ATTRIBUTES');

// Payments
export const GET_PAYMENTS = Utils.createRequestTypes('GET_PAYMENTS');

// Pay Page
export const GET_LICENSE_INFO = Utils.createRequestTypes('GET_LICENSE_INFO');
export const GET_TARRIFS = Utils.createRequestTypes('GET_TARRIFS');
export const CREATE_LICENSE = Utils.createRequestTypes('CREATE_LICENSE');
export const CREATE_LICENSE_STATE_CHANGES = Utils.createRequestTypes(
  'CREATE_LICENSE_STATE_CHANGES'
);

// Single License
export const GET_LICENSE = Utils.createRequestTypes('GET_LICENSE');
export const GET_LICENSE_PAYMENTS = Utils.createRequestTypes('GET_LICENSE_PAYMENTS');
export const GET_LICENSE_USERS = Utils.createRequestTypes('GET_LICENSE_USERS');
export const UPDATE_LICENSE_USER_STATUS = Utils.createRequestTypes('UPDATE_LICENSE_USER_STATUS');
export const CANCEL_LICENSE = Utils.createRequestTypes('CANCEL_LICENSE');
export const UPDATE_LICENSE_COMMENT = Utils.createRequestTypes('UPDATE_LICENSE_COMMENT');
export const UPDATE_LICENSE_RPS = Utils.createRequestTypes('UPDATE_LICENSE_RPS');
export const GET_EXTRA_STAGE_CHANGES = Utils.createRequestTypes('GET_EXTRA_STAGE_CHANGES');
export const GET_EXTRA_STAGE_CHANGES_PRICE = Utils.createRequestTypes(
  'GET_EXTRA_STAGE_CHANGES_PRICE'
);
export const GET_UPDATE_PAYMENT_METHOD = Utils.createRequestTypes('GET_UPDATE_PAYMENT_METHOD');
export const GET_LICENSE_DASHBOARD = Utils.createRequestTypes('GET_LICENSE_DASHBOARD');

// Workspaces
export const GET_WORKSPACES = Utils.createRequestTypes('GET_WORKSPACES');
export const SEARCH_WORKSPACES = Utils.createRequestTypes('SEARCH_WORKSPACES');
export const CREATE_WORKSPACE = Utils.createRequestTypes('CREATE_WORKSPACE');
export const MODIFY_WORKSPACE = Utils.createRequestTypes('MODIFY_WORKSPACE');
export const DELETE_WORKSPACE = Utils.createRequestTypes('DELETE_WORKSPACE');
export const LEAVE_WORKSPACE = Utils.createRequestTypes('LEAVE_WORKSPACE');
export const MODIFY_WORKSPACE_OWNER = Utils.createRequestTypes('MODIFY_WORKSPACE_OWNER');
export const GET_ENV_WORKSPACES = Utils.createRequestTypes('GET_ENV_WORKSPACES');
export const GET_USER_WORKSPACES_BY_ID = Utils.createRequestTypes('GET_USER_WORKSPACES_BY_ID');

// Workspace
export const GET_WORKSPACE = Utils.createRequestTypes('GET_WORKSPACE');
export const CLEAR_WORKSPACE = 'GET_WORKSPACE.CLEAR';
export const MODIFY_SINGLE_WORKSPACE = Utils.createRequestTypes('MODIFY_SINGLE_WORKSPACE');
export const GET_WORKSPACE_USER_INFO = Utils.createRequestTypes('GET_WORKSPACE_USER_INFO');
export const GET_WORKSPACE_USER_ROLE_PERMS = Utils.createRequestTypes(
  'GET_WORKSPACE_USER_ROLE_PERMS'
);
export const COMPANY_SIZES = ['1-5', '6-25', '26-100', '101-500', '501-1000', '1001+'];

// Roles
export const GET_ROLES = Utils.createRequestTypes('GET_ROLES');
export const GET_AVAILABLE_ROLES = Utils.createRequestTypes('GET_AVAILABLE_ROLES');
export const RESET_ROLES_AND_PERMS = Utils.createRequestTypes('RESET_ROLES_AND_PERMS');
export const CREATE_ROLE = Utils.createRequestTypes('CREATE_ROLE');
export const DELETE_ROLE = Utils.createRequestTypes('DELETE_ROLE');
export const MODIFY_ROLE = Utils.createRequestTypes('MODIFY_ROLE');
export const MODIFY_ROLE_PERMS = Utils.createRequestTypes('MODIFY_ROLE_PERMS');
export const GET_PERMS = Utils.createRequestTypes('GET_PERMS');

export const MODIFY_ROLE_USERS = Utils.createRequestTypes('MODIFY_ROLE_USERS');
export const GET_ROLE_USERS = Utils.createRequestTypes('GET_ROLE_USERS');

// Single Roles
export const GET_ROLE = Utils.createRequestTypes('GET_ROLE');
export const MODIFY_SINGLE_ROLE = Utils.createRequestTypes('MODIFY_SINGLE_ROLE');

// Users
export const GET_USERS_SUGGESTION = Utils.createRequestTypes('GET_USERS_SUGGESTION');
export const GET_USERS = Utils.createRequestTypes('GET_USERS');
export const MODIFY_USER = Utils.createRequestTypes('MODIFY_USER');
export const DELETE_USER = Utils.createRequestTypes('DELETE_USER');
export const SEARCH_USERS = Utils.createRequestTypes('SEARCH_USERS');
export const GET_USER_GROUPS = Utils.createRequestTypes('GET_USER_GROUPS');
export const MANAGE_USER_GROUPS = Utils.createRequestTypes('MANAGE_USER_GROUPS');
export const MANAGE_USER_ROLES = Utils.createRequestTypes('MANAGE_USER_ROLES');
export const GET_ENV_USERS = Utils.createRequestTypes('GET_ENV_USERS');
export const RESET_2FA_USER = Utils.createRequestTypes('RESET_2FA_USER');
export const LOGOUT_ENV_USER = Utils.createRequestTypes('LOGOUT_ENV_USER');
export const MODIFY_ENV_USER = Utils.createRequestTypes('MODIFY_ENV_USER');

// Invites
export const GET_INVITES = Utils.createRequestTypes('GET_INVITES');
export const CREATE_INVITE = Utils.createRequestTypes('CREATE_INVITE');
export const DELETE_INVITE = Utils.createRequestTypes('DELETE_INVITE');

// Groups
export const GET_GROUPS = Utils.createRequestTypes('GET_GROUPS');
export const CREATE_GROUP = Utils.createRequestTypes('CREATE_GROUP');
export const DELETE_GROUP = Utils.createRequestTypes('DELETE_GROUP');
export const MODIFY_GROUP = Utils.createRequestTypes('MODIFY_GROUP');
export const MODIFY_GROUP_OWNER = Utils.createRequestTypes('MODIFY_GROUP_OWNER');

// Single Group
export const GET_GROUP = Utils.createRequestTypes('GET_GROUP');
export const MODIFY_SINGLE_GROUP = Utils.createRequestTypes('MODIFY_SINGLE_GROUP');
export const MODIFY_GROUP_USERS = Utils.createRequestTypes('MODIFY_GROUP_USERS');
export const MODIFY_GROUP_API_KEYS = Utils.createRequestTypes('MODIFY_GROUP_API_KEYS');
export const GET_GROUP_USERS = Utils.createRequestTypes('GET_GROUP_USERS');
export const GET_GROUP_API_KEYS = Utils.createRequestTypes('GET_GROUP_API_KEYS');

// API Keys & Scopes
export const GET_SCOPES = Utils.createRequestTypes('GET_SCOPES');

export const SEARCH_API_KEYS = Utils.createRequestTypes('SEARCH_API_KEYS');
export const GET_API_KEYS_SUGGESTION = Utils.createRequestTypes('GET_API_KEYs_SUGGESTION');
export const GET_API_KEYS = Utils.createRequestTypes('GET_API_KEYS');
export const CREATE_API_KEY = Utils.createRequestTypes('CREATE_API_KEY');
export const MODIFY_API_KEY = Utils.createRequestTypes('MODIFY_API_KEY');
export const DELETE_API_KEY = Utils.createRequestTypes('DELETE_API_KEY');

// Webhooks
export const SEARCH_WEBHOOKS = Utils.createRequestTypes('SEARCH_WEBHOOKS');
export const GET_API_WEBHOOKS = Utils.createRequestTypes('GET_API_WEBHOOKS');
export const CREATE_WEBHOOK = Utils.createRequestTypes('CREATE_WEBHOOK');
export const MODIFY_WEBHOOK = Utils.createRequestTypes('MODIFY_WEBHOOK');
export const DELETE_WEBHOOK = Utils.createRequestTypes('DELETE_WEBHOOK');

// Owner Requests
export const GET_OWNER_REQUESTS = Utils.createRequestTypes('GET_OWNER_REQUESTS');
export const ADD_OWNER_REQUEST = Utils.createRequestTypes('ADD_OWNER_REQUEST');
export const UPDATE_OWNER_REQUESTS = Utils.createRequestTypes('UPDATE_OWNER_REQUESTS');

// UPLOAD
export const UPLOAD_FILE = Utils.createRequestTypes('UPLOAD_FILE');

// Products
export const GET_PRODUCTS = Utils.createRequestTypes('GET_PRODUCTS');

// COMMON
export const SET_LOCATION = 'SET_LOCATION';
export const EMAIL = 'email';
export const CODE = 'code';
export const PASSWORD = 'password';
export const USER_NAME = 'userName';
export const CAPTCHA = 'captcha';
export const SET_MODAL = 'SET_MODAL';
export const DEL_MODAL = 'DEL_MODAL';
export const PASSWORD_MIN_LENGTH = 9;
export const PASSWORD_MIN_LENGTH_REGEXP = '(?=.{9,})';
export const PASSWORD_MAX_LENGTH = 255;
export const PASSWORD_MAX_LENGTH_REGEXP = '(?=.{,255})';
export const PASSWORD_UPPERCASE_SYMBOL = '(?=.*[A-Z])';
export const PASSWORD_LOWERCASE_SYMBOL = '(?=.*[a-z])';
export const PASSWORD_NUMBER_SYMBOL = '(?=.*[0-9])';
export const PASSWORD_SPECIAL_SYMBOL = '(?=.*[ \\\\!"#$%&\'()*+,-./:;<=>?@[\\]^_`{|}~])';
export const PASSWORD_REGEXP = `^.{${PASSWORD_MIN_LENGTH},${PASSWORD_MAX_LENGTH}}$`;
export const PASSWORD_REGEXP_FULL = `^${PASSWORD_LOWERCASE_SYMBOL}${PASSWORD_UPPERCASE_SYMBOL}${PASSWORD_NUMBER_SYMBOL}${PASSWORD_SPECIAL_SYMBOL}${PASSWORD_MIN_LENGTH_REGEXP}`;
export const USER_NAME_MIN_LENGTH = 3;
export const USER_NAME_MAX_LENGTH = 255;
export const USER_NAME_REGEXP = `^[a-zA-Z\u0400-\u04FF-'ʼ’0-9_ ]{${USER_NAME_MIN_LENGTH},${USER_NAME_MAX_LENGTH}}$`;
export const DOMAIN_NANE_REGEXP = `^[a-zA-Z0-9-]+\\.([a-zA-Z]{2,}\\.[a-zA-Z]{2,}|[a-zA-Z]{2,})$`;
export const EMAIL_REGEXP = `^[\\w\\-_.]+@[\\w-]+(\\.[\\w-]+)+$`;
export const NUMBER_REGEXP = '^[0-9]+$';
export const WORKSPACE_NAME = 'workspaceName';
export const WORKSPACE_NAME_MIN_LENGTH = 3;
export const WORKSPACE_NAME_MAX_LENGTH = 255;
export const EMPLOYEES_COUNT = 'employeesCount';

const BASE_INPUT_NAME = `[\\p{L}\+.,@'ʼ’"\`0-9_ -]`;
const BASE_NAME = `${BASE_INPUT_NAME}{${1},${USER_NAME_MAX_LENGTH}}`;

export const USER_NAME_REGEXP_NEW = new RegExp(`^${BASE_NAME}$`, 'u');

export const USER_NAME_REGEXP_RANGE_NEW = new RegExp(
  `^${BASE_INPUT_NAME}{${USER_NAME_MIN_LENGTH},${USER_NAME_MAX_LENGTH}}$`,
  'u'
);

// Payment

export const LICENSE_TYPE = 'licenseType';
export const USERS = 'users';
export const RPS = 'RPS';
export const YEAR_DISCOUNT_ACTIVE = 'yearDiscountActive';

// API PATH
export const API_PATH = '/face/api/1';

export const NOTIFY_LEVEL = {
  SUCCESS: 'success',
  INFO: 'normal',
  WARNING: 'warning',
  ERROR: 'error'
};

export const VALIDATE_PASSWORD = [
  {
    key: 'upper',
    name: 'uppercase_symbol',
    regexp: RegExp(PASSWORD_UPPERCASE_SYMBOL)
  },
  {
    key: 'special',
    name: 'special_symbol',
    regexp: RegExp(PASSWORD_SPECIAL_SYMBOL)
  },
  {
    key: 'lower',
    name: 'lowercase_symbol',
    regexp: RegExp(PASSWORD_LOWERCASE_SYMBOL)
  },
  {
    key: 'minSymbols',
    name: 'symbols_minimum',
    regexp: RegExp(PASSWORD_MIN_LENGTH_REGEXP)
  },
  {
    key: 'number',
    name: 'numbers_symbol',
    regexp: RegExp(PASSWORD_NUMBER_SYMBOL)
  }
];

export const PERM_KEYS = {
  SA: 'perms.sa',
  CONTROL: 'perms.control',
  ENV_SA: 'perms.sa.env',
  ENV_SA_CLOUD: 'perms.sa.env.cloud',
  COREZOID: 'perms.corezoid'
};

export const permGroups = {
  [PERM_KEYS.SA]: 'Account',
  [PERM_KEYS.COREZOID]: 'Corezoid',
  [PERM_KEYS.CONTROL]: 'Simulator.Company',
  [PERM_KEYS.ENV_SA]: 'Environment administration',
  [PERM_KEYS.ENV_SA_CLOUD]: 'License administration'
};

export const licenseLabelTexts = {
  cloud: 'Cloud',
  in_house: 'In-House',
  desktop: 'Desktop'
};

export const sortOrders = {
  asc: 'asc',
  desc: 'desc'
};

export const accountComponents = {
  'scopes.sa': 'Account',
  'scopes.control': 'Simulator',
  'scopes.corezoid': 'Corezoid'
};

export const workspaceStatus = {
  active: 'active',
  blocked: 'blocked'
};

export const NULL_WORKSPACE_TYPE = 'null';

export const DEBOUNCE_MS = 300;
export const WORKSPACES_LIMIT = 100;

export const WEBHOOK_STATUS = {
  ACTIVE: 'active',
  INACTIVE: 'inactive'
};

export const TRANSFER_OWNERSHIP_REQ = {
  PENDING: 'pending',
  APPROVED: 'approved',
  CANCELED: 'canceled',
  REJECTED: 'rejected'
};
