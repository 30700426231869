/* eslint-disable camelcase */
import { call, put, takeLatest } from 'redux-saga/effects';
import * as ActionTypes from 'constants';
import { Utils } from 'mw-style-react';
import { api } from './apiRequest';

const queryParams = Utils.getQueryParam(location.search);
const { CLIENT } = ActionTypes;

// Получение конфига приложения
function* client() {
  const { client_id } = queryParams;
  if (!client_id) return; // eslint-disable-line camelcase
  const { result, data } = yield call(api, {
    method: 'post',
    url: '/api/1/json/public',
    body: {
      obj: 'oauth_client',
      type: 'get',
      client_id
    },
    withApiPath: false
  });
  if (result === 'success') {
    yield put({ type: CLIENT.SUCCESS, payload: data });
  } else if (result === 'error') {
    yield put({ type: CLIENT.FAILURE });
  }
}

function* configSaga() {
  yield takeLatest(CLIENT.REQUEST, client);
}

export default configSaga;
