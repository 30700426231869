import { defineMessages } from 'react-intl';
import globalIntl from 'globalIntl'; // eslint-disable-line import/no-unresolved

const m = defineMessages({
  deleteApplication: {
    id: 'deleteApplication',
    defaultMessage: 'Delete Application'
  },
  cancel: {
    id: 'cancel',
    defaultMessage: 'Cancel'
  },
  delete: {
    id: 'delete',
    defaultMessage: 'Delete'
  }
});

export default { ...globalIntl, ...m };
