import { SET_THEME } from 'constants';

// ------------------------------------
// Reducer
// ------------------------------------

export default function themeReducer(state = 'light', action) {
  switch (action.type) {
    case SET_THEME.SUCCESS:
      return action.payload;
    default:
      return state;
  }
}
