import { createBrowserHistory } from 'history';

let historyObj = null;

export function setHistory(basename) {
  const params = { basename };
  historyObj = createBrowserHistory(params);
  return historyObj;
}

export default function history() {
  return historyObj;
}
