import { defineMessages } from 'react-intl';
import globalIntl from 'globalIntl'; // eslint-disable-line import/no-unresolved

const m = defineMessages({
  titleCancelLicense: {
    id: 'titleCancelLicense',
    defaultMessage: 'Cancel license'
  },
  btnCancelLicense: {
    id: 'btnCancelLicense',
    defaultMessage: 'Submit'
  }
});

export default { ...globalIntl, ...m };
