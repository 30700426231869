import { defineMessages } from 'react-intl';
import globalIntl from 'globalIntl'; // eslint-disable-line import/no-unresolved

const m = defineMessages({
  modifyGroupApiKeysHeader: {
    id: 'modifyGroupApiKeysHeader',
    defaultMessage: 'Assign to group'
  },
  modifyGroupApiKeysButton: {
    id: 'modifyGroupApiKeysButton',
    defaultMessage: 'Save'
  },
  modifyGroupApiKeysEmptyList: {
    id: 'modifyGroupApiKeysEmptyList',
    defaultMessage: 'There are no Api Keys yet in this group.'
  }
});

export default { ...globalIntl, ...m };
