import { defineMessages } from 'react-intl';
import globalIntl from 'globalIntl'; // eslint-disable-line import/no-unresolved

const m = defineMessages({
  inviteToWorkspace: {
    id: 'inviteToWorkspace',
    defaultMessage: 'Invite members'
  },
  inviteDescription: {
    id: 'inviteDescription',
    defaultMessage:
      'Invite user to your workspace. New user will have access after the log in. <br/>Enter login and select role.'
  },
  inviteLoginsLabel: {
    id: 'inviteLoginsLabel',
    defaultMessage: 'Login'
  },
  inviteLoginsPlaceholder: {
    id: 'inviteLoginsPlaceholder',
    defaultMessage: 'Enter email or login'
  },
  inviteRolePlaceholder: {
    id: 'inviteRolePlaceholder',
    defaultMessage: 'Choose user`s role'
  },
  inviteRoleLabel: {
    id: 'inviteRoleLabel',
    defaultMessage: 'Role'
  },
  btnInvite: {
    id: 'btnInvite',
    defaultMessage: 'Send invite'
  }
});

export default { ...globalIntl, ...m };
