import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Upload, Divider, MenuItem, PortalWrapper, ColorPicker, Utils } from 'mw-style-react';
import cn from 'classnames';
import { UPLOAD_FILE, SHOW_NOTIFY, NOTIFY_LEVEL } from 'constants';
import ImageUploader from '../ImageUploader/ImageUploader';
import EditIcon from '../../images/edit-icon.svg';
import './ImageUploaderEditable.scss';

/**
 * Редактируемый аватар актора на панели
 * @returns {*}
 * @constructor
 */
function ImageUploaderEditable(props) {
  const {
    dispatch,
    size,
    pictureUrl,
    colors = [],
    accessDenied,
    status,
    iconLabel,
    formType,
    formTitle,
    handleChange,
    disabled,
    menuItemsLabels = {
      add: 'Add avatar',
      clear: 'Clear avatar'
    },
    showEditIcon = false,
    disabledLabel
  } = props;
  const avatarRef = useRef();
  const menuRef = useRef();
  const [menu, toggleMenu] = useState(false);
  const [picker, togglePicker] = useState(false);
  const actorColor = colors.find(i => i.type === 'actor') || {};
  const formColor = colors.find(i => i.type === 'form') || {};
  const mainColor = actorColor && actorColor.color ? actorColor : formColor;
  const [bgColor, setBgColor] = useState(
    mainColor && mainColor.color ? mainColor.color : '#ffffff'
  );
  const [pickerColor, setPickerColor] = useState(null);
  const hasCustomColor = actorColor && actorColor.color && actorColor.color !== formColor.color;

  const handleClick = ({ target }) => {
    if (
      avatarRef.current &&
      !avatarRef.current.contains(target) &&
      menuRef.current &&
      !menuRef.current.contains(target)
    ) {
      if (target.tagName === 'INPUT' && target.getAttribute('type') === 'file') return;
      toggleMenu(false);
      togglePicker(false);
    }
  };

  useEffect(() => {
    if (mainColor && mainColor.color !== bgColor) {
      setBgColor(mainColor.color);
    }
  }, [mainColor, bgColor]);

  useEffect(() => {
    const rootNode = document.getElementById('root');
    rootNode.addEventListener('click', handleClick, true);
    return () => {
      rootNode.removeEventListener('click', handleClick);
    };
  }, []);

  /**
   * Применить аватар
   */
  const changeAvatar = (newPicture = null) => {
    const newPictureUrl = newPicture ? `${location.origin}/${newPicture}` : '';
    handleChange({
      pictureUrl: newPictureUrl,
      color: ''
    });
    setPickerColor(null);
  };

  /**
   * Применить цвет
   */
  const changeColor = (color = null) => {
    handleChange({ color, pictureUrl: '' });
  };

  /**
   * Изменить цвет
   */
  const onChangeColor = (hex = '') => {
    setPickerColor(hex);
    changeColor(hex);
  };

  /**
   * Загрузить изображение для актора
   */
  const handleUploadPicture = data => {
    if (data.value && data.value.length > 0) {
      dispatch({
        type: UPLOAD_FILE.REQUEST,
        payload: {
          files: [data.value[data.value.length - 1]],
          type: '',
          callback: ({ result, data = {}, message }) => {
            toggleMenu(false);
            if (result === 'error' || data.result === 'error') {
              dispatch({
                type: SHOW_NOTIFY.REQUEST,
                payload: {
                  id: Utils.random().toString(),
                  type: NOTIFY_LEVEL.ERROR,
                  label: message || data.message || 'File exceeds maximum size limit (1MB)'
                }
              });
            } else {
              changeAvatar(data.hash);
            }
          }
        }
      });
    }
  };

  const renderColorPicker = () => (
    <PortalWrapper
      root={document.getElementById('mainRoot')}
      node={avatarRef.current}
      width={200}
      offsetX={272}
      offsetY={20}
    >
      <ColorPicker
        value={pickerColor || bgColor}
        onChange={({ hex }) => onChangeColor(hex)}
        onSave={null}
        disableAlpha={true}
      />
    </PortalWrapper>
  );

  const renderAvatarMenu = () => (
    <PortalWrapper
      root={document.getElementById('mainRoot')}
      node={avatarRef.current}
      width={220}
      offsetX={44}
      offsetY={20}
    >
      <div ref={menuRef} styleName="editable__avatar__menu" onClick={e => e.stopPropagation()}>
        <Upload
          styleName="editable__avatar__replace"
          type="avatar"
          accept="image/*"
          preview={false}
          onChange={obj => {
            togglePicker(false);
            handleUploadPicture(obj);
          }}
        >
          <MenuItem
            styleName="editable__avatar__menu__item"
            leftIcon="camera"
            label={pictureUrl ? 'Replace' : menuItemsLabels.add}
            onClick={() => {}}
          />
        </Upload>
        <MenuItem
          styleName="editable__avatar__menu__item"
          leftIcon="palette"
          rightIcon="arrow"
          label="Set custom color"
          onClick={() => togglePicker(!picker)}
        />
        {pictureUrl || hasCustomColor ? (
          <Divider styleName="editable__avatar__menu__divider" />
        ) : null}
        {pictureUrl ? (
          <MenuItem
            leftIcon="trash"
            label={menuItemsLabels.clear}
            onClick={() => {
              changeAvatar();
              toggleMenu(false);
              togglePicker(false);
            }}
          />
        ) : null}
        {hasCustomColor ? (
          <MenuItem
            leftIcon="trash"
            label="Reset to default color"
            visibility="visible"
            onClick={() => {
              changeColor(formColor.color);
              setPickerColor(formColor.color);
              toggleMenu(false);
              togglePicker(false);
            }}
          />
        ) : null}
      </div>
    </PortalWrapper>
  );

  return (
    <div
      styleName={cn('editable__avatar', {
        disabled
      })}
      ref={avatarRef}
      onClick={e => {
        e.stopPropagation();
        if (disabled) return;
        toggleMenu(true);
      }}
    >
      <ImageUploader
        size={size}
        icon={!pictureUrl && !formType ? 'picture' : undefined}
        disabledLabel={disabledLabel}
        formType={formType}
        formTitle={formTitle}
        iconLabel={iconLabel}
        pictureUrl={pictureUrl}
        color={pickerColor || bgColor}
        colorFilled={true}
        colors={colors}
        accessDenied={accessDenied}
        status={status}
      />
      {showEditIcon && pictureUrl && (
        <div styleName="editable__avatar-edit-icon">
          <EditIcon />
        </div>
      )}
      {menu ? renderAvatarMenu() : null}
      {picker ? renderColorPicker() : null}
    </div>
  );
}

ImageUploaderEditable.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'large', 'xlarge', 'xxlarge']),
  dispatch: PropTypes.func,
  iconLabel: PropTypes.string,
  pictureUrl: PropTypes.string,
  colors: PropTypes.array,
  accessDenied: PropTypes.bool,
  status: PropTypes.oneOf(['verified', 'rejected', 'pending', null]),
  formType: PropTypes.string,
  formTitle: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  menuItemsLabels: PropTypes.object,
  showEditIcon: PropTypes.bool,
  disabledLabel: PropTypes.string
};

export default ImageUploaderEditable;
