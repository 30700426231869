import { SET_LOCATION } from 'constants';

// ------------------------------------
// Reducer
// ------------------------------------

function makeLocaction(pathname) {
  const pathParts = pathname.split('/');
  if (!pathParts[1]) return '';
  return pathParts[1];
}

export default function locationReducer(state = '', action) {
  return action.type === SET_LOCATION ? makeLocaction(action.payload) : state;
}
