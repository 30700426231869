import { defineMessages } from 'react-intl';
import globalIntl from 'globalIntl'; // eslint-disable-line import/no-unresolved

const m = defineMessages({
  modifyRoleUsersHeader: {
    id: 'modifyRoleUsersHeader',
    defaultMessage: 'Manage users'
  },
  modifyRoleUsersButton: {
    id: 'modifyRoleUsersButton',
    defaultMessage: 'Save'
  },
  modifyRoleUsersEmptyList: {
    id: 'modifyRoleUsersEmptyList',
    defaultMessage: 'There are no users yet.'
  }
});

export default { ...globalIntl, ...m };
