import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Modal, ModalButtons, ModalContent, Button } from 'mw-style-react';
import { DELETE_ROLE } from 'constants';
import cn from 'classnames';
import { useParams } from 'react-router-dom';
import TextButton from '../../../TextButton';
import history from '../../../../store/history';
import mes from './intl';
import useIntl from '../../../../hooks/useIntl';
import sModal from '../../Modal.scss';

function DeleteRole({ visibility, onClose, data, dispatch }) {
  const [isSubmit, setIsSubmit] = useState(false);
  const params = useParams();
  const t = useIntl();

  const handleSubmit = () => {
    setIsSubmit(true);

    dispatch({
      type: DELETE_ROLE.REQUEST,
      payload: {
        params: {
          workspaceId: data.workspaceId,
          roleId: data.id
        },
        callback: result => {
          if (result === 'error') {
            setIsSubmit(false);
          } else {
            if (params.itemId && params.category && params.workspaceId) {
              history().push(`/workspace/${params.workspaceId}/${params.category}`);
            }
            onClose();
          }
        }
      }
    });
  };

  return (
    <Modal visibility={visibility} onClose={onClose} styleName="sModal.modal__workspace">
      <div styleName="sModal.modal__header">
        <div styleName="sModal.modal__header__close">
          <TextButton title="Cancel" onClick={onClose} />
        </div>
        <div styleName="sModal.modal__header__title">{t(mes.titleDeleteRole)}</div>
      </div>
      <div styleName="sModal.modal__body">
        <ModalContent styleName="sModal.modal__content">
          <div>
            <div styleName="sModal.modal__description">
              Are you sure you want to delete the role <strong>{name}</strong>? If this role is the
              user&apos;s last role, then their role will be changed to &quot;member&quot;. This
              action is irreversible.
            </div>
          </div>
        </ModalContent>
        <ModalButtons styleName="sModal.modal__buttons">
          <Button
            label={t(mes.btnDeleteRole)}
            size="medium"
            onClick={handleSubmit}
            styleName={cn('sModal.modal__btn', 'sModal.red', 'sModal.wide', {
              red__disabled: isSubmit
            })}
            visibility={isSubmit ? 'disabled' : 'visible'}
            disabled={isSubmit}
          />
        </ModalButtons>
      </div>
    </Modal>
  );
}

DeleteRole.propTypes = {
  visibility: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  data: PropTypes.object,
  dispatch: PropTypes.func
};

export default React.memo(DeleteRole);
