import { defineMessages } from 'react-intl';
import globalIntl from 'globalIntl'; // eslint-disable-line import/no-unresolved

const m = defineMessages({
  manageUserRolesHeader: {
    id: 'manageUserRolesHeader',
    defaultMessage: 'Manage roles'
  },
  manageUserRolesButton: {
    id: 'manageUserRolesButton',
    defaultMessage: 'Save'
  },
  manageUserRolesEmptyList1: {
    id: 'manageUserRolesEmptyList1',
    defaultMessage: 'User can`t stay in workspace without any role.'
  },
  manageUserRolesEmptyList2: {
    id: 'manageUserRolesEmptyList2',
    defaultMessage: 'Please, choose role from the list above'
  }
});

export default { ...globalIntl, ...m };
