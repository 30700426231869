/* eslint-disable max-len */
import { Utils } from 'mw-style-react';
import { string as SchemaString } from 'yup';
import { NOTIFY_LEVEL, SHOW_NOTIFY, USER_NAME_REGEXP_NEW, sortOrders, EMAIL } from 'constants';
import moment from 'moment';
import { getSessionTimestampProfileImage } from './localeStorage';

/**
 * Утилиты
 */
const AppUtils = {
  // Получить сообщение локализации
  getMes(context) {
    const ct = context.intl;
    return function res(mesId, values) {
      try {
        return ct.formatMessage(mesId, values);
      } catch (e) {
        return mesId;
      }
    };
  },

  formatPrice(price) {
    if (price === 0) {
      return 'Free';
    }

    const formattedPrice = `$${price.toFixed(2)}`;
    return formattedPrice;
  },

  // Сформировать url с get параметрами
  makeUrl(url, params) {
    if (!Object.keys(params).length) return url;
    const divider = url.includes('?') ? '&' : '?';
    return `${url}${divider}${Utils.serialize(params)}`;
  },

  makeImgUrl(img) {
    let fullPath = img;
    if (!/^(http|https):\//.test(img)) {
      fullPath = `/static/${img}`;
    }
    return fullPath;
  },

  // валидация и кориктеровка redirect uri
  controlRedirectUrl(redirectUrl, whitelist) {
    if (!redirectUrl) return '';
    // используем только первую часть redirect_uri
    const first = redirectUrl.split('?');

    if (whitelist && whitelist.length > 0) {
      let isRedirect = false;
      whitelist.forEach(item => {
        if (first[0].indexOf(item) !== -1) {
          isRedirect = true;
        }
      });
      if (!isRedirect) return '';
    }

    return redirectUrl.substring(0, 4) === 'http' ? redirectUrl : '';
  },

  logoutRedirectUrl() {
    return encodeURIComponent(`${document.location.origin}/profile`);
  },

  validateEmail(email) {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  },

  validateUrl(url) {
    return String(url)
      .toLowerCase()
      .match(
        /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/
      );
  },

  validateName(name) {
    return USER_NAME_REGEXP_NEW.test(String(name));
  },

  isValidHttpUrl(string) {
    let url;
    try {
      url = new URL(string);
    } catch (_) {
      return false;
    }
    return url.protocol === 'http:' || url.protocol === 'https:';
  },

  isValidEmailSchema(email, ldapLogin) {
    // If ldap is enabled, then validate email input as text
    if (ldapLogin) {
      const textSchema = SchemaString().required();
      return textSchema.isValidSync(email);
    }

    // Otherwise, validate email input as email
    const emailSchema = SchemaString().email().required();
    return emailSchema.isValidSync(email);
  },

  onChangeFormField(value, params = {}) {
    if (value.id === EMAIL) {
      const email = (value.value || '').trim();
      const isValidEmail = this.isValidEmailSchema(email, params.ldapLogin);
      return { ...value, value: email, error: !isValidEmail };
    }
    return { ...value };
  },

  transformURL(obj, workspaceId, enable) {
    const whitelist = { corezoid: 'corezoid', control: 'control' };
    if (enable && obj.name === whitelist.corezoid && workspaceId) {
      return `${this.removeTrailingSlash(obj.homepage)}/${workspaceId}/workspace/project/0`;
    }

    if (enable && obj.name === whitelist.control && workspaceId) {
      return `${this.removeTrailingSlash(obj.homepage)}/events/${workspaceId}`;
    }

    return obj.redirect_uri || obj.homepage;
  },

  removeTrailingSlash(url) {
    if (typeof url !== 'string') {
      throw new Error('Input must be a string');
    }
    return url.replace(/\/+$/, '');
  },

  intlParse({ intl = {}, mesId = {} }) {
    const { messages } = intl;
    return messages[mesId.id] || mesId.defaultMessage;
  },

  download(url, fileName, dispatch = () => {}) {
    fetch(`${url}`)
      .then(response => {
        if (!response.ok) {
          dispatch({
            type: SHOW_NOTIFY.REQUEST,
            payload: {
              id: Utils.random().toString(),
              type: NOTIFY_LEVEL.ERROR,
              label: 'Failed to download the file'
            }
          });
          throw new Error(`Failed to download the file: ${response.status}`);
        }
        return response;
      })
      .then(t =>
        t.blob().then(b => {
          const a = document.createElement('a');
          a.href = URL.createObjectURL(b);
          a.setAttribute('download', fileName);
          a.click();
        })
      );
  },
  validateLicense(status) {
    return status !== 'canceled';
  },
  urlWithParams(baseURL, paramsObject) {
    const params = new URLSearchParams();

    Object.keys(paramsObject).forEach(key => {
      const value = paramsObject[key];
      if (value !== undefined && value !== null && value !== '') {
        if (Array.isArray(value)) {
          params.append(key, value.join(','));
        } else {
          params.append(key, value);
        }
      }
    });

    return `${baseURL}?${params.toString()}`;
  },

  getFallbackImage() {
    if (__PRE__ || __DEV__) {
      return `https://account.pre.corezoid.com/avatars/0.jpg`;
    }
    if (__PROD__) {
      return `https://account.corezoid.com/avatars/0.jpg`;
    }
  },

  getManagementLicenses(perms) {
    if (!perms) return false;
    const perm = perms.find(p => p.type === 'sa.env');
    return perm ? perm.ids.includes('licensesManagement') : false;
  },

  getViewLicenses(perms) {
    if (!perms) return false;
    const perm = perms.find(p => p.type === 'sa.env');
    return perm ? perm.ids.includes('licensesView') : false;
  },

  getViewUsers(perms) {
    if (!perms) return false;
    const perm = perms.find(p => p.type === 'sa.env');
    return perm ? perm.ids.includes('usersView') : false;
  },

  getManagmenetUsers(perms) {
    if (!perms) return false;
    const perm = perms.find(p => p.type === 'sa.env');
    return perm ? perm.ids.includes('usersManagement') : false;
  },

  getViewCloudLicenses(perms) {
    if (!perms) return false;
    const perm = perms.find(p => p.type === 'sa.env.cloud');
    return perm ? perm.ids.includes('licensesView') : false;
  },

  getManageLicenses(perms) {
    if (!perms) return false;
    const perm = perms.find(p => p.type === 'sa.env');
    return perm ? perm.ids.includes('licensesManagement') : false;
  },

  getManageCloudLicenses(perms) {
    if (!perms) return false;
    const perm = perms.find(p => p.type === 'sa.env.cloud');
    return perm ? perm.ids.includes('licensesManagement') : false;
  },

  getViewWorkspaces(perms) {
    if (!perms) return false;
    const perm = perms.find(p => p.type === 'sa.env');
    return perm ? perm.ids.includes('workspacesView') : false;
  },

  getManageWorkspaces(perms) {
    if (!perms) return false;
    const perm = perms.find(p => p.type === 'sa.env');
    return perm ? perm.ids.includes('workspacesManagement') : false;
  },

  getViewEnvironmentSettings(perms) {
    if (!perms) return false;
    const perm = perms.find(p => p.type === 'sa.env');
    return perm ? perm.ids.includes('settingsView') : false;
  },

  getManageEnvironmentSettings(perms) {
    if (!perms) return false;
    const perm = perms.find(p => p.type === 'sa.env');
    return perm ? perm.ids.includes('settingsManagement') : false;
  },

  mapLicenseAttributes(data) {
    const COLUMNS = {
      current: 'Installed values',
      new: 'Proposed value'
    };

    const LABELS = {
      account_license_module: 'Account license module',
      cluster_id: 'Cluster name',
      company_id: 'Company name',
      created_time: 'Created time',
      is_allow: 'Is allow',
      issuer: 'Issuer',
      max_active_procs: 'Max active procs',
      max_rpm: 'Max requests per month',
      max_rps: 'Max requests per second',
      max_scpm: 'Max state changes per month',
      max_storage_size: 'Max storage size',
      max_task_size_process: 'Max task size for process',
      max_task_size_state_diagram: 'Max task size for state diagram',
      max_users: 'Max users',
      min_timer: 'Min timer',
      multi_tenancy: 'Multi tenancy',
      time_to_expire: 'Time to expire',
      time_to_start: 'Time to start',
      pub_key: 'Pub key'
    };
    const FORMAT_DATE = 'DD.MM.yyyy HH:mm';
    const PLACEHOLDER = '-';

    const keys = [];
    const attrs = [];

    // Get keys and attributes from API response
    Object.keys(data).forEach(key => {
      if (key !== 'ref') {
        keys.push(key);

        Object.keys(data[key]).forEach(attr => {
          if (!attrs.includes(attr)) {
            attrs.push(attr);
          }
        });
      }
    });

    // Format value to render
    const valueToRender = ({ val, attr }) => {
      const getValue = value =>
        value !== null && value !== undefined ? String(value) : PLACEHOLDER;

      const value = getValue(val);

      switch (attr) {
        case 'created_time':
          const format = 'D-MMM-YYYY::HH:mm:ss [UTC]';
          const createdDate = moment(val, format);
          return createdDate.isValid() ? createdDate.format(FORMAT_DATE) : PLACEHOLDER;
        case 'time_to_expire':
        case 'time_to_start':
          const time = moment(val * 1000);
          return time.isValid() ? time.format(FORMAT_DATE) : PLACEHOLDER;
        case 'max_active_procs':
        case 'max_rpm':
        case 'max_rps':
        case 'max_scpm':
        case 'max_storage_size':
        case 'max_task_size_process':
        case 'max_task_size_state_diagram':
        case 'max_users':
        case 'min_timer':
          const formatNumericValue = val => {
            if (!val) return val;
            const numValue = Number(val);
            const isFinite = Number.isFinite(numValue);
            const isSafeNumber = Number.isSafeInteger(numValue);

            return isFinite
              ? isSafeNumber
                ? val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
                : 'Infinite'
              : val;
          };

          return formatNumericValue(value);
        default:
          return value;
      }
    };

    return {
      columns: keys.map(key => COLUMNS[key]),
      rows: attrs.map(attr => {
        return {
          title: LABELS[attr] || attr,
          values: keys.map(key => valueToRender({ val: data[key][attr], attr })),
          isEqual: keys.length === 1 ? null : data[keys[0]][attr] === data[keys[1]][attr]
        };
      })
    };
  },

  sortWebhooks({ data, sortBy, orderBy }) {
    const sortedWebhooks = data ? [...data] : [];

    sortedWebhooks.sort((a, b) => {
      const elA = a.id;
      const elB = b.id;
      return orderBy === sortOrders.desc ? elB - elA : elA - elB;
    });

    if (sortBy === 'created_at') {
      sortedWebhooks.sort((a, b) => {
        const dateA = new Date(a.created_at).getTime();
        const dateB = new Date(b.created_at).getTime();
        return orderBy === 'asc' ? dateA - dateB : dateB - dateA;
      });
    }

    const compareStrings = (a, b) => {
      return orderBy === sortOrders.desc ? b.localeCompare(a) : a.localeCompare(b);
    };

    if (sortBy === 'url') {
      sortedWebhooks.sort((a, b) => compareStrings(a.url, b.url));
    }

    if (sortBy === 'owner_name') {
      sortedWebhooks.sort((a, b) => compareStrings(a.owner.name, b.owner.name));
    }

    if (sortBy === 'status') {
      sortedWebhooks.sort((a, b) => compareStrings(a.status, b.status));
    }

    return sortedWebhooks;
  },
  /**
   * Создает дебаунсированную версию функции
   * @param func Функция, которую нужно дебаунсировать
   * @param delay Задержка в миллисекундах
   * @returns Дебаунсированная версия функции
   */
  debounced(func, delay) {
    let timerId;
    // eslint-disable-next-line func-names
    return function (...args) {
      if (timerId) {
        clearTimeout(timerId);
      }
      timerId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  },

  getCustomTemplateParams(val) {
    const url = new URL(val);
    const params = url.searchParams;
    const photo = params.get('photo');
    const title = params.get('title');
    return `${photo ? `&photo=${photo}` : ''}${title ? `&title=${title}` : ''}`;
  },

  canCreateWorkspace(auth) {
    if (!auth || !auth.options) {
      return true;
    }
    return !auth.options.disable_workspace_creation;
  },

  getCachedUserImage(url) {
    const stamp = getSessionTimestampProfileImage();
    return url && stamp ? `${url}?${stamp}` : url;
  }
};

export default AppUtils;
