import React from 'react';
import cn from 'classnames';
import { ProgressBar } from 'mw-style-react';
import './Loader.scss';
import PropTypes from 'prop-types';

function Loader({ cover = false }) {
  return (
    <div
      styleName={cn('Loader', {
        'is-cover': cover
      })}
    >
      <ProgressBar />
    </div>
  );
}

Loader.propTypes = {
  cover: PropTypes.bool
};

export default Loader;
