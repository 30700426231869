import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Modal, ModalButtons, ModalContent, Button, Checkbox, Label } from 'mw-style-react';
import { DELETE_WORKSPACE } from 'constants';
import cn from 'classnames';
import { useParams } from 'react-router-dom';
import TextButton from '../../../TextButton';
import mes from './intl';
import useIntl from '../../../../hooks/useIntl';
import sModal from '../../Modal.scss';

function DeleteWorkspace({ visibility, onClose, data, dispatch }) {
  const [isSubmit, setIsSubmit] = useState(false);
  const [deleteWorkspace, setDeleteWorkspace] = useState(false);
  const t = useIntl();
  const params = useParams();
  const allChecked = !deleteWorkspace;

  const handleSubmit = () => {
    const { ext_id: workspaceId } = data;
    setIsSubmit(true);

    dispatch({
      type: DELETE_WORKSPACE.REQUEST,
      payload: {
        params: { workspaceId },
        callback: result => {
          if (result === 'error') {
            setIsSubmit(false);
          } else {
            onClose();
            setTimeout(() => {
              if (params.workspaceId) {
                location.href = '/choose/delete';
              }
            }, 800);
          }
        }
      }
    });
  };

  const changeCheckbox = () => setDeleteWorkspace(prevState => !prevState);

  return (
    <Modal visibility={visibility} onClose={onClose} styleName="sModal.modal__workspace">
      <div styleName="sModal.modal__header">
        <div styleName="sModal.modal__header__close">
          <TextButton title="Cancel" onClick={onClose} />
        </div>
        <div styleName="sModal.modal__header__title">{t(mes.deleteWorkspace)}</div>
      </div>
      <div styleName="sModal.modal__body">
        <ModalContent styleName="sModal.modal__content">
          <div>
            <div styleName="sModal.modal__description">
              Are you sure you want to delete the <strong>{data.name}</strong> workspace? <br />
              This action is irreversible.
            </div>
            <div styleName="sModal.modal__checkboxGroup">
              <Checkbox
                styleName="sModal.modal__checkbox__display__label"
                value={deleteWorkspace}
                onChange={changeCheckbox}
              >
                <Label value="Delete" />{' '}
                <span>
                  <strong>workspace</strong>
                </span>
                {', '}
                <span>
                  <strong>users</strong>
                </span>
                {', '}
                <span>
                  <strong>groups</strong>
                </span>
                {', '}
                <span>
                  <strong>data</strong>
                </span>
                {', and '}
                <span>
                  <strong>roles</strong>
                </span>
                .
              </Checkbox>
            </div>
          </div>
        </ModalContent>
        <ModalButtons styleName="sModal.modal__buttons">
          <Button
            label={t(mes.btnDelete)}
            size="medium"
            onClick={handleSubmit}
            styleName={cn('sModal.modal__btn', 'sModal.red', 'sModal.wide', {
              red__disabled: allChecked || isSubmit
            })}
            visibility={allChecked || isSubmit ? 'disabled' : 'visible'}
            disabled={allChecked || isSubmit}
          />
        </ModalButtons>
      </div>
    </Modal>
  );
}

DeleteWorkspace.propTypes = {
  visibility: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  data: PropTypes.object,
  dispatch: PropTypes.func
};

export default React.memo(DeleteWorkspace);
