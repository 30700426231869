import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { intlShape, injectIntl } from 'react-intl';
import {
  Modal,
  ModalButtons,
  ModalContent,
  Button,
  ProgressBar,
  Label,
  TextField
} from 'mw-style-react';
import { SEND_QR_CODE, GET_QR_CODE } from 'constants';
import AppUtils from '../../../../utils/utils';
import mes from './intl';
import Auth from '../../../../images/auth.svg';
import AppStore from '../../../../images/app_store.svg';
import AppStoreBadge from '../../../../images/app_store_badge.svg';
import GooglePlay from '../../../../images/google_play.svg';
import GooglePlayBadge from '../../../../images/google_play_badge.svg';
import authLike from '../../../../images/2FAuthLike.png';
import sModal from '../../Modal.scss'; // eslint-disable-line no-unused-vars
import sLocal from './TwoFactorAuth.scss'; // eslint-disable-line no-unused-vars
import QR_ANDROID from '../../../../images/android.png';
import QR_IOS from '../../../../images/ios.png';
import { getMobileOS } from '../../../../utils/helperFunctions';

class TwoFactorAuth extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      step: 1,
      qrCode: '',
      qrCodeValue: '',
      qrCodeError: false,
      helperText: '',
      os: getMobileOS()
    };
  }

  // Ф-ция локализации
  i(mesId, values) {
    return AppUtils.getMes(this.context)(mesId, values);
  }

  handleInputChange(field, name) {
    this.setState({
      [name]: field.value,
      qrCodeError: false,
      helperText: ''
    });
  }

  setStep(step) {
    if (step === 2) {
      this.props.dispatch({
        type: GET_QR_CODE.REQUEST,
        callback: code => {
          this.setState({
            qrCode: code.qr_code_b64
          });
        }
      });
    }

    if (step === 5) {
      this.props.dispatch({
        type: SEND_QR_CODE.REQUEST,
        payload: this.state.qrCodeValue,
        callback: data => {
          if (data.result === 'error') {
            this.setState({
              qrCodeError: true,
              helperText: data.message || data.description || '',
              step: 4
            });
          } else {
            this.setState({
              step
            });
          }
        }
      });
    } else {
      this.setState({
        step
      });
    }
  }

  renderContent(step) {
    switch (step) {
      case 1:
        return (
          <div styleName="sModal.modal__content sLocal.content">
            <div styleName="sLocal.center">
              <Auth width={100} height={100} style={{ margin: 8 }} />
            </div>
            <div styleName="sLocal.center">
              <Label
                value={"We use Google's two-factor authentication to secure your account"}
                fontWeight="semibold"
              />
            </div>
            <Label
              value={
                "With the Google Authenticator app, you'll get a randomly generated code to login, even without an internet or mobile network connection. And if an attacker finds out the password for your account, they won't be able to access it without entering the code."
              }
            />
          </div>
        );

      case 2:
        return (
          <div styleName="sModal.modal__content sLocal.content">
            <div styleName="sLocal.center">
              {this.state.os === 'iOS' || this.state.os === 'Android' ? null : (
                <div styleName="qr-code-image-container">
                  <div styleName="qr-code-image-container__column">
                    <img
                      src={QR_IOS}
                      alt="qrCode"
                      width="80px"
                      height="80px"
                      styleName="qr-code-image"
                    />
                    <div styleName="qr-code-image-container__label">
                      <AppStore width={20} height={20} />
                      App Store
                    </div>
                  </div>
                  <div styleName="qr-code-image-container__column">
                    <img
                      src={QR_ANDROID}
                      alt="qrCode"
                      width="80px"
                      height="80px"
                      styleName="qr-code-image"
                    />
                    <div styleName="qr-code-image-container__label">
                      <GooglePlay width={20} height={20} /> Google Play
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div styleName="sLocal.center">
              <Label value="Please install Google Authenticator" fontWeight="semibold" />
            </div>

            {this.state.os === 'iOS' || this.state.os === 'Android' ? (
              <div styleName="qr-code-badges">
                <a
                  href="https://apps.apple.com/ru/app/google-authenticator/id388497605"
                  target="_blank"
                >
                  <AppStoreBadge style={{ transform: 'scale(1.34)' }} />
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en"
                  target="_blank"
                >
                  <GooglePlayBadge />
                </a>
              </div>
            ) : null}

            {this.state.os === 'iOS' || this.state.os === 'Android' ? null : (
              <Label value="Scan with the camera or a dedicated application the QR code below with a link to the Google Authenticator application and install it on your smartphone." />
            )}
          </div>
        );

      case 3:
        return (
          <div styleName="sModal.modal__content sLocal.content">
            <div styleName="sLocal.center">
              {this.state.qrCode ? (
                <img
                  src={`data:image/jpeg;base64,${this.state.qrCode}`}
                  alt="qrCode"
                  width="100px"
                  height="100px"
                />
              ) : (
                <div style={{ height: '144px' }} />
              )}
            </div>
            <div styleName="sLocal.center">
              <Label value="Setup Google Authenticator" fontWeight="semibold" />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Label
                value={
                  '1. Open the Google Authenticator application on your smartphone and click "Get started".'
                }
              />
              <Label value={'2. Select the "Barcode" option and scan the QR code.'} />
            </div>
          </div>
        );

      case 4:
        return (
          <div styleName="sModal.modal__content sLocal.content">
            <div styleName="sLocal.center">
              <Label value="Enter verification code" fontWeight="semibold" />
            </div>
            <div styleName="sLocal.center">
              <Label value="Enter the six-digit code displayed in Google Authenticator" />
            </div>
            <div styleName="sLocal.center">
              <TextField
                value={this.state.qrCodeValue}
                onChange={e => this.handleInputChange(e, 'qrCodeValue')}
                bordered={true}
                error={this.state.qrCodeError}
                helperText={this.state.helperText}
              />
            </div>
          </div>
        );

      case 5:
        return (
          <div styleName="sModal.modal__content sLocal.content">
            <div styleName="sLocal.center">
              <img src={authLike} alt="auth" width="100px" height="100px" />
            </div>
            <div styleName="sLocal.center">
              <Label value="Congratulations" fontWeight="semibold" />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Label value="Configuring two-factor authentication is complete. Your account is now secure. On the next logging into an account, we will ask you for a six-digit code from the Google Authenticator application." />
              <div>
                <Label value="If you have any difficulties or questions, please contact " />
                <a href="mailto: support@corezoid.com">support@corezoid.com</a>.
              </div>
            </div>
          </div>
        );
      default:
        return <div />;
    }
  }

  calculateWidth(step) {
    if (step === 1) {
      return '0%';
    }
    return `${(step - 1) * 25}%`;
  }

  render() {
    const { visibility, onClose, isSubmit } = this.props;

    const { step } = this.state;

    const steps = [1, 2, 3, 4, 5];

    return (
      <Modal
        styleName="sLocal.titleHead"
        visibility={visibility}
        onClose={onClose}
        label="Enable Two-factor authentication"
      >
        <ModalContent styleName="sModal.modal__content sLocal.content">
          <div styleName="sLocal.steps">
            {steps.map((s, index) => (
              <div
                key={index}
                styleName={step >= s ? 'sLocal.step sLocal.step__active' : 'sLocal.step'}
              >
                <Label value={s} fontSize="large" fontWeight="semibold" />
              </div>
            ))}
            <div styleName="progress-line">
              <div
                styleName="progress-line__bar"
                style={{
                  width: this.calculateWidth(step)
                }}
              />
            </div>
          </div>
          {this.renderContent(step)}
        </ModalContent>
        <ModalButtons styleName="sModal.modal__buttons">
          {step !== 5 && step > 1 ? (
            <Button
              label="Back"
              size="medium"
              type="text"
              onClick={() => this.setStep(step - 1)}
              visibility={isSubmit ? 'disabled' : 'visible'}
              disabled={isSubmit}
            />
          ) : (
            <Button
              label={this.i(mes.cancel)}
              size="medium"
              type="text"
              onClick={onClose}
              visibility={step === 5 ? 'hidden' : 'visible'}
            />
          )}
          {step === 5 ? (
            <Button
              label="Finish"
              size="medium"
              onClick={onClose}
              visibility={isSubmit ? 'disabled' : 'visible'}
              disabled={isSubmit}
            />
          ) : (
            <Button
              label="Next"
              size="medium"
              onClick={() => this.setStep(step + 1)}
              visibility={isSubmit ? 'disabled' : 'visible'}
              disabled={isSubmit}
            />
          )}
          <ProgressBar
            styleName="sModal.modal__loader"
            type="circle"
            size="small"
            visibility={isSubmit ? 'visible' : 'hidden'}
          />
        </ModalButtons>
      </Modal>
    );
  }
}

TwoFactorAuth.propTypes = {
  visibility: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  isSubmit: PropTypes.bool,
  dispatch: PropTypes.func
};

TwoFactorAuth.contextTypes = {
  intl: intlShape
};

export default injectIntl(TwoFactorAuth);
