import { UPLOAD_FILE } from 'constants';
import { call, takeLatest } from 'redux-saga/effects';
import { api } from './apiRequest';

/**
 * Загрузить файл
 * @param payload
 * @returns {IterableIterator<*>}
 */
function* upload({ payload }) {
  const data = yield call(api, {
    method: 'post',
    url: '/api/1/upload',
    body: {
      namespace: 'avatars',
      file: payload.files[0].fileSource
    },
    reqType: 'multipart',
    showError: false,
    withApiPath: false
  });

  if (payload.callback) payload.callback(data);
}

function* uploadFile() {
  yield takeLatest(UPLOAD_FILE.REQUEST, upload);
}

export default uploadFile;
