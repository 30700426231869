import { defineMessages } from 'react-intl';
import globalIntl from 'globalIntl'; // eslint-disable-line import/no-unresolved

const m = defineMessages({
  titleDeleteGroup: {
    id: 'titleDeleteGroup',
    defaultMessage: 'Delete group'
  },
  btnDeleteGroup: {
    id: 'btnDeleteGroup',
    defaultMessage: 'Delete'
  }
});

export default { ...globalIntl, ...m };
