/* eslint-disable react/require-default-props */
import React from 'react';
import cn from 'classnames';
import { Tooltip } from 'mw-style-react';
import PropTypes from 'prop-types';
import DoneIcon from '../../images/done.svg';
import GridIcon from '../../images/grid-view.svg';
import CopyIcon from '../../images/copy-icon.svg';
import MoreIcon from '../../images/more-icon.svg';
import InviteIcon from '../../images/invite-icon.svg';
import BackIcon from '../../images/back-icon.svg';
import TrashIcon from '../../images/trash-icon.svg';
import ChevronIcon from '../../images/chevron-icon.svg';
import TriangleUpIcon from '../../images/triangle-up-icon.svg';
import IndeterminateIcon from '../../images/indeterminate-checkbox.svg';
import EditIcon from '../../images/edit-icon.svg';
import CloseIcon from '../../images/сlose-icon.svg';
import GroupIcon from '../../images/new-group-icon.svg';
import HideIcon from '../../images/hide.svg';
import SearchIcon from '../../images/search.svg';

import './IconButton.scss';

const iconEnum = {
  done: DoneIcon,
  grid: GridIcon,
  copy: CopyIcon,
  more: MoreIcon,
  invite: InviteIcon,
  back: BackIcon,
  trash: TrashIcon,
  chevron: ChevronIcon,
  triangle: TriangleUpIcon,
  indeterminate: IndeterminateIcon,
  edit: EditIcon,
  close: CloseIcon,
  group: GroupIcon,
  hide: HideIcon,
  search: SearchIcon
};

function IconButton({ onClick = () => {}, icon, active, disabled, style = {}, type, size, title }) {
  const clickProxy = e => {
    if (disabled) return;
    onClick(e);
  };

  const Icon = icon && iconEnum[icon];
  return (
    <Tooltip value={title || ''}>
      <button
        styleName={cn('iconButton', {
          active,
          disabled,
          secondary: type === 'secondary',
          large: size === 'large',
          medium: size === 'medium',
          small: size === 'small',
          micro: size === 'micro'
        })}
        onClick={clickProxy}
        type="button"
        style={style}
      >
        {icon && <Icon />}
      </button>
    </Tooltip>
  );
}

IconButton.propTypes = {
  onClick: PropTypes.func,
  icon: PropTypes.string.isRequired,
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  style: PropTypes.object,
  type: PropTypes.string,
  size: PropTypes.string,
  title: PropTypes.string
};

export default IconButton;
