import { defineMessages } from 'react-intl';
import globalIntl from 'globalIntl'; // eslint-disable-line import/no-unresolved

const m = defineMessages({
  newRole: {
    id: 'newRole',
    defaultMessage: 'New role'
  },
  roleNameLabel: {
    id: 'roleNameLabel',
    defaultMessage: 'Name'
  },
  btnCreateRole: {
    id: 'btnCreateRole',
    defaultMessage: 'Create'
  },
  createRoleUsersEmptyList: {
    id: 'createRoleUsersEmptyList',
    defaultMessage: 'There are no users yet.'
  }
});

export default { ...globalIntl, ...m };
