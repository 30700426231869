import { put, takeLatest, select } from 'redux-saga/effects';
import * as ActionTypes from 'constants';

const { SHOW_NOTIFY, HIDE_NOTIFY } = ActionTypes;

// нотификация отображаем
function* showNotify(action) {
  const notify = yield select(state => state.notify);
  const { items } = notify;
  // Создаем новый массив
  const newItems = items.slice(0);
  const item = action.payload;
  newItems.unshift(item);
  yield put({ type: SHOW_NOTIFY.SUCCESS, payload: { items: newItems } });
}

// нотификация скрываем
function* hideNotify(action) {
  const notify = yield select(state => state.notify);
  const { items } = notify;
  // Создаем новый массив
  const newItems = items.slice(0);
  const item = action.payload;
  const removeIndex = newItems.map(i => i.id).indexOf(item.id);
  newItems.splice(removeIndex, 1);
  yield put({ type: HIDE_NOTIFY.SUCCESS, payload: { items: newItems } });
}

function* notifySaga() {
  yield takeLatest(SHOW_NOTIFY.REQUEST, showNotify);
  yield takeLatest(HIDE_NOTIFY.REQUEST, hideNotify);
}

export default notifySaga;
