import { defineMessages } from 'react-intl';
import globalIntl from 'globalIntl'; // eslint-disable-line import/no-unresolved

const m = defineMessages({
  modifyRole: {
    id: 'modifyRole',
    defaultMessage: 'Rename role'
  },
  roleNameLabelModify: {
    id: 'roleNameLabelModify',
    defaultMessage: 'Name'
  },
  btnModifyRole: {
    id: 'btnModifyRole',
    defaultMessage: 'Rename'
  }
});

export default { ...globalIntl, ...m };
