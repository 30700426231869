import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Modal, ModalButtons, ModalContent, Button } from 'mw-style-react';
import { DELETE_ORGANIZATION_IDP_MAP } from 'constants';
import cn from 'classnames';
import TextButton from '../../../TextButton';
import sModal from '../../Modal.scss';

function DeleteMapper({ visibility, onClose, data, dispatch }) {
  const [isSubmit, setIsSubmit] = useState(false);

  const handleSubmit = () => {
    setIsSubmit(true);

    dispatch({
      type: DELETE_ORGANIZATION_IDP_MAP.REQUEST,
      payload: {
        params: {
          idpId: data.idpId,
          mapId: data.mapId,
          orgId: data.orgId
        },
        callback: result => {
          if (result === 'success' && data.callback) {
            data.callback(data.mapId);
          }

          if (result === 'error') {
            setIsSubmit(false);
          } else {
            onClose();
          }
        }
      }
    });
  };

  return (
    <Modal visibility={visibility} onClose={onClose} styleName="sModal.modal__workspace">
      <div styleName="sModal.modal__header">
        <div styleName="sModal.modal__header__close">
          <TextButton title="Cancel" onClick={onClose} />
        </div>
        <div styleName="sModal.modal__header__title">Remove mapper</div>
      </div>
      <div styleName="sModal.modal__body">
        <ModalContent styleName="sModal.modal__content">
          <div>
            <div styleName="sModal.modal__description">
              Are you sure you want to delete the mapper <strong>{data.name}</strong>?
            </div>
          </div>
        </ModalContent>
        <ModalButtons styleName="sModal.modal__buttons">
          <Button
            label="Remove"
            size="medium"
            onClick={handleSubmit}
            styleName={cn('sModal.modal__btn', 'sModal.red', 'sModal.wide', {
              red__disabled: isSubmit
            })}
            visibility={isSubmit ? 'disabled' : 'visible'}
            disabled={isSubmit}
          />
        </ModalButtons>
      </div>
    </Modal>
  );
}

DeleteMapper.propTypes = {
  visibility: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  data: PropTypes.object,
  dispatch: PropTypes.func
};

export default React.memo(DeleteMapper);
