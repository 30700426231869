import { defineMessages } from 'react-intl';
import globalIntl from 'globalIntl'; // eslint-disable-line import/no-unresolved

const m = defineMessages({
  newWorkspace: {
    id: 'newWorkspace',
    defaultMessage: 'New workspace'
  },
  nameLabel: {
    id: 'nameLabel',
    defaultMessage: 'Name'
  },
  btnCreate: {
    id: 'btnCreate',
    defaultMessage: 'Create'
  }
});

export default { ...globalIntl, ...m };
