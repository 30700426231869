import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

import './CustomAvatar.scss';
import { getContrastColor, getInitials } from '../../utils/helperFunctions';

const sizes = {
  medium: 'medium',
  xxlarge: 'xxlarge'
};

function CustomAvatar({ photo = '', color = '', name = '', size = sizes.medium, children }) {
  return (
    <div
      styleName={cn('custom__avatar', {
        xxlarge: size === sizes.xxlarge
      })}
      style={{
        backgroundImage: photo && photo.length ? `url(${photo})` : null,
        backgroundColor: color && color.length && !(photo && photo.length) ? color : null
      }}
    >
      {!(photo && photo.length) && name && (
        <span style={{ color: getContrastColor(color) }}>{getInitials(name)}</span>
      )}
      {!(photo && photo.length) && children && children}
    </div>
  );
}

CustomAvatar.propTypes = {
  photo: PropTypes.string,
  color: PropTypes.string,
  name: PropTypes.string,
  size: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node])
};

export default CustomAvatar;
