import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { intlShape, injectIntl } from 'react-intl';
import { Modal, ModalButtons, ModalContent, Button, TextField } from 'mw-style-react';
import { CREATE_WORKSPACE } from 'constants';
import AppUtils from '../../../../utils/utils';
import TextButton from '../../../TextButton';
import history from '../../../../store/history';
import ImageUploaderEditable from '../../../ImageUplaoderEditable/ImageUploaderEditable';
import mes from './intl';
import sModal from '../../Modal.scss'; // eslint-disable-line no-unused-vars

class CreateWorkspace extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isSubmit: false,
      name: '',
      color: undefined,
      photo: undefined,
      error: false
    };
  }

  // Ф-ция локализации
  i(mesId, values) {
    return AppUtils.getMes(this.context)(mesId, values);
  }

  handleSubmit() {
    const { dispatch, onClose } = this.props;
    const { name, photo = '', color = '' } = this.state;

    if (!name.length || !AppUtils.validateName(name)) {
      this.setState({
        error: true
      });
      return;
    }

    this.setState({
      isSubmit: true
    });

    dispatch({
      type: CREATE_WORKSPACE.REQUEST,
      payload: {
        body: {
          name,
          photo: photo.length ? photo : undefined,
          color: color.length ? color : undefined
        },
        callback: (result, workspaceId) => {
          if (result === 'error') {
            this.setState({
              isSubmit: false
            });
          } else {
            onClose();
            history().push(`/workspace/${workspaceId}/user`);
          }
        }
      }
    });
  }

  handleOnChange(e) {
    const { id, value } = e;
    this.setState({
      [id]: value,
      error: !AppUtils.validateName(value)
    });
  }

  managePicture(data) {
    const { pictureUrl, color } = data || {};
    this.setState({
      photo: pictureUrl,
      color
    });
  }

  render() {
    const { visibility, onClose, dispatch } = this.props;

    const { name, color, photo, isSubmit, error } = this.state;

    return (
      <Modal visibility={visibility} onClose={onClose} styleName="sModal.modal__workspace">
        <div styleName="sModal.modal__header">
          <div styleName="sModal.modal__header__close">
            <TextButton title="Cancel" onClick={onClose} />
          </div>
          <div styleName="sModal.modal__header__title">{this.i(mes.newWorkspace)}</div>
        </div>
        <div styleName="sModal.modal__body">
          <ModalContent styleName="sModal.modal__content">
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: '16px',
                marginBottom: '20px'
              }}
            >
              <div>
                <div styleName="avatar__group__info">
                  <div styleName="avatar__group__info__title">Avatar</div>
                  <ImageUploaderEditable
                    size="xxlarge"
                    colorFilled={true}
                    iconLabel="Add image"
                    handleChange={this.managePicture.bind(this)}
                    pictureUrl={photo}
                    colors={[
                      { type: 'actor', color },
                      { type: 'form', color: '#BAD5F8' }
                    ]}
                    dispatch={dispatch}
                    showEditIcon
                  />
                  <div styleName="avatar__group__info__description">PNG, JPG up to 1 MB</div>
                </div>
              </div>
              <div style={{ flexGrow: 1 }}>
                <div styleName="sModal.modal__input__label">{this.i(mes.nameLabel)}</div>
                <TextField
                  styleName="sModal.modal__input"
                  value={name}
                  onChange={this.handleOnChange.bind(this)}
                  length={255}
                  autoFocus
                  bordered
                  error={error}
                  id="name"
                  visibility={isSubmit ? 'disabled' : 'visible'}
                />
              </div>
            </div>
          </ModalContent>
          <ModalButtons styleName="sModal.modal__buttons">
            <Button
              label={this.i(mes.btnCreate)}
              size="medium"
              onClick={this.handleSubmit.bind(this)}
              visibility={isSubmit || this.state.error ? 'disabled' : 'visible'}
              disabled={isSubmit || this.state.error}
              styleName="sModal.modal__btn sModal.wide"
            />
          </ModalButtons>
        </div>
      </Modal>
    );
  }
}

CreateWorkspace.propTypes = {
  visibility: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  dispatch: PropTypes.func
};

CreateWorkspace.contextTypes = {
  intl: intlShape
};

export default injectIntl(CreateWorkspace);
