/* eslint-disable react/jsx-indent */
/* eslint-disable max-len */
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { intlShape, injectIntl } from 'react-intl';
import { Modal, ModalButtons, ModalContent, Button } from 'mw-style-react';
import { UPDATE_LICENSE_RPS } from 'constants';
import cn from 'classnames';
import AppUtils from '../../../../utils/utils';
import TextButton from '../../../TextButton';
import mes from './intl';
import CheckIcon from '../../../../images/check-select.svg';
import './ChangeLicenseRPS.styles.scss';
import sModal from '../../Modal.scss'; // eslint-disable-line no-unused-vars

const RPS_VALUES = [5, 25, 50, 100, 200, 500];

class ChangeLicenseRPS extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isSubmit: false,
      selectedRPS: props.data.value || RPS_VALUES[5]
    };
  }

  // Ф-ция локализации
  i(mesId, values) {
    return AppUtils.getMes(this.context)(mesId, values);
  }

  handleSubmit() {
    const { dispatch, onClose } = this.props;

    this.setState({
      isSubmit: true
    });

    dispatch({
      type: UPDATE_LICENSE_RPS.REQUEST,
      payload: {
        params: {
          id: this.props.data.licenseId
        },
        body: {
          value: this.state.selectedRPS
        },
        callback: result => {
          if (result === 'error') {
            this.setState({
              isSubmit: false
            });
          } else {
            onClose();
          }
        }
      }
    });
  }

  handleSelectValue(val) {
    this.setState({
      selectedRPS: val
    });
  }

  getUrl() {
    if (__PRE__ || __DEV__) {
      return `https://pre.simulator.company/buy`;
    }
    if (__PROD__) {
      return `https://simulator.company/buy`;
    }
  }

  redirectInHouse() {
    const url = this.getUrl();
    document.location.href = url;
  }

  render() {
    const { visibility, onClose } = this.props;

    const { isSubmit } = this.state;

    return (
      <Modal visibility={visibility} onClose={onClose} styleName="sModal.modal__workspace">
        <div styleName="sModal.modal__header">
          <div styleName="sModal.modal__header__close">
            <TextButton title="Cancel" onClick={onClose} />
          </div>
          <div styleName="sModal.modal__header__title">{this.i(mes.titleChangeRPS)}</div>
        </div>
        <div styleName="sModal.modal__body" style={{ overflow: 'auto' }}>
          <ModalContent styleName="sModal.modal__content">
            <div>
              <div styleName="sModal.modal__description">
                <div styleName="change-rps">
                  {RPS_VALUES.map(val => {
                    return (
                      <div
                        styleName={cn({
                          'change-rps__item': true,
                          'is-active': val === this.state.selectedRPS
                        })}
                        key={val}
                        onClick={() => this.handleSelectValue(val)}
                      >
                        <div
                          styleName={cn({
                            'change-rps__item-check': true,
                            'is-active': val === this.state.selectedRPS
                          })}
                        >
                          <CheckIcon />
                        </div>
                        {val} RPS
                      </div>
                    );
                  })}
                </div>
                <div styleName="change-rps__note">
                  Need more 500 RPS?{' '}
                  <span styleName="change-rps__note-link" onClick={() => this.redirectInHouse()}>
                    Try In-House license
                  </span>
                </div>
              </div>
            </div>
          </ModalContent>
          <ModalButtons styleName="sModal.modal__buttons">
            <Button
              label={this.i(mes.btnChangeRPS)}
              size="medium"
              onClick={this.handleSubmit.bind(this)}
              styleName={cn('sModal.modal__btn', 'sModal.wide')}
              visibility={isSubmit ? 'disabled' : 'visible'}
              disabled={isSubmit}
            />
          </ModalButtons>
        </div>
      </Modal>
    );
  }
}

ChangeLicenseRPS.propTypes = {
  visibility: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  data: PropTypes.object,
  dispatch: PropTypes.func
};

ChangeLicenseRPS.contextTypes = {
  intl: intlShape
};

export default injectIntl(ChangeLicenseRPS);
