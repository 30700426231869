import { defineMessages } from 'react-intl';
import globalIntl from 'globalIntl'; // eslint-disable-line import/no-unresolved

const m = defineMessages({
  blockApiKey: {
    id: 'blockApiKey',
    defaultMessage: 'Block Api Key'
  },
  btnBlockApiKey: {
    id: 'btnBlockApiKey',
    defaultMessage: 'Block'
  }
});

export default { ...globalIntl, ...m };
