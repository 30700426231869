import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import {
  Modal,
  ModalButtons,
  ModalContent,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Utils,
  ProgressBar
} from 'mw-style-react';
import cn from 'classnames';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import TextButton from '../../../TextButton';
import sModal from '../../Modal.scss';
import './ChangeAuthIdp.scss';
import WorkspacePreview from '../../../../routes/Settings/components/Workspaces/components/WorkspacePreview';
import UserPanel from '../../../UserPanel/UserPanel';
import { copyToClipboard } from '../../../../utils/helperFunctions';
import {
  GET_ENV_WORKSPACES,
  GET_WORKSPACES,
  NOTIFY_LEVEL,
  SHOW_NOTIFY
} from '../../../../constants';
import CopyIcon from '../../../../images/copy-icon.svg';

class ChangeAuthIdp extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isSubmit: false,
      workspaces: [...props.data.values.workspaces],
      hasMore: props.data.values.hasMore,
      isEnv: props.data.isEnv,
      offset: 0
    };
  }

  handleSubmit() {
    const { onClose, data } = this.props;
    this.setState({
      isSubmit: true
    });

    if (data.callback) {
      data.callback();
    }
    onClose();
  }

  handleCopy(e, id) {
    e.preventDefault();
    e.stopPropagation();

    const result = copyToClipboard(id);

    if (result) {
      this.props.dispatch({
        type: SHOW_NOTIFY.REQUEST,
        payload: {
          id: Utils.random().toString(),
          type: NOTIFY_LEVEL.SUCCESS,
          label: `Workspace ID: ${id} has been copied to clipboard`
        }
      });
    }
  }

  fetchMore() {
    const { isEnv, offset, hasMore } = this.state;
    const { dispatch } = this.props;
    if (!hasMore) return;

    const nextOffset = offset + 100;

    dispatch({
      type: isEnv ? GET_ENV_WORKSPACES.REQUEST : GET_WORKSPACES.REQUEST,
      payload: {
        params: {
          limit: 100,
          offset: nextOffset,
          orgId: isEnv ? '' : 'me',
          search: '',
          merge: true
        },
        callback: (result, data, hasMore, total) => {
          const values = {
            workspaces: isEnv ? data.data : data,
            hasMore,
            total: isEnv ? data.meta.total : total
          };
          if (result === 'success') {
            this.setState(prevState => ({
              workspaces: [...prevState.workspaces, ...values.workspaces],
              hasMore: values.hasMore,
              offset: nextOffset
            }));
          }
        }
      }
    });
  }

  Row({ index, style }) {
    const itemLoading = index === this.state.workspaces.length;
    const workspace = this.state.workspaces[index];

    if (itemLoading) {
      return (
        <div style={style}>
          <TableRow>
            <TableCell colspan={3} styleName="change-auth-idp__table-loader">
              <ProgressBar size="small" />
            </TableCell>
          </TableRow>
        </div>
      );
    }
    return (
      <div style={style}>
        <TableRow key={workspace.id} styleName="change-auth-idp__row">
          <TableCell>
            <WorkspacePreview data={workspace} />
          </TableCell>
          <TableCell>
            {(workspace.owners && workspace.owners.length) || workspace.owner ? (
              <UserPanel
                data={workspace.owners ? workspace.owners[0] : workspace.owner}
                isSingleLine
              />
            ) : (
              '-'
            )}
          </TableCell>
          <TableCell styleName="change-auth-idp__last-cell">
            <div
              styleName="change-auth-idp__copy-button"
              onClick={e => this.handleCopy(e, workspace.ext_id)}
            >
              <CopyIcon />
            </div>
          </TableCell>
        </TableRow>
      </div>
    );
  }

  render() {
    const { visibility, onClose } = this.props;

    const { isSubmit, hasMore, workspaces } = this.state;
    const itemCount = hasMore ? workspaces.length + 1 : workspaces.length;

    return (
      <Modal
        visibility={visibility}
        onClose={onClose}
        size="large"
        styleName="sModal.modal__workspace sModal.modal__fixed-size"
      >
        <div styleName="sModal.modal__header">
          <div styleName="sModal.modal__header__close">
            <TextButton title="Cancel" onClick={onClose} />
          </div>
          <div styleName="sModal.modal__header__title">Workspace Ownership</div>
        </div>
        <div
          style={{
            minHeight: 300,
            overflow: 'auto',
            display: 'flex',
            flexDirection: 'column',
            flex: 1
          }}
        >
          <div
            style={{ fontWeight: 600, fontSize: 12, color: '#7e8a9a', padding: '20px 20px 0 20px' }}
          >
            Your teammates have created{' '}
            {workspaces.length > 1 ? 'multiple workspaces' : 'workspace'}. All Workspaces must be
            owned by a License Owner when enabling mappers.
            <br /> Click <span style={{ color: 'black' }}>Continue</span> to transfer these
            Workspaces to your account.
          </div>
          <div styleName="sModal.modal__body" style={{ overflow: 'hidden' }}>
            <ModalContent styleName="sModal.modal__content is-static">
              <Table paddingSize="small" styleName="sModal.modal__workspaces-table">
                <TableHead fixed>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Owner</TableCell>
                    <TableCell styleName="change-auth-idp__last-cell" />
                  </TableRow>
                </TableHead>

                <TableBody styleName="sModal.modal__table-body">
                  <InfiniteLoader
                    isItemLoaded={index => index < workspaces.length}
                    itemCount={itemCount}
                    loadMoreItems={this.fetchMore.bind(this)}
                  >
                    {({ onItemsRendered, ref }) => (
                      <AutoSizer id="autosize">
                        {({ height, width }) => (
                          <FixedSizeList
                            height={height}
                            width={width}
                            itemCount={itemCount}
                            itemSize={56}
                            onItemsRendered={onItemsRendered}
                            ref={ref}
                          >
                            {this.Row.bind(this)}
                          </FixedSizeList>
                        )}
                      </AutoSizer>
                    )}
                  </InfiniteLoader>
                </TableBody>
              </Table>
            </ModalContent>
            <br />
            <ModalButtons styleName="sModal.modal__buttons">
              <Button
                label="Continue"
                size="medium"
                onClick={this.handleSubmit.bind(this)}
                styleName={cn('sModal.modal__btn', 'sModal.wide')}
                visibility={isSubmit ? 'disabled' : 'visible'}
                disabled={isSubmit}
              />
            </ModalButtons>
          </div>
        </div>
      </Modal>
    );
  }
}

ChangeAuthIdp.propTypes = {
  visibility: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  data: PropTypes.object,
  callback: PropTypes.func,
  dispatch: PropTypes.func
};

export default ChangeAuthIdp;
