import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { intlShape, injectIntl } from 'react-intl';
import { Modal, ModalButtons, ModalContent, Button } from 'mw-style-react';
import cn from 'classnames';
import { DELETE_WEBHOOK } from 'constants';
import TextButton from '../../../TextButton';
import sModal from '../../Modal.scss'; // eslint-disable-line no-unused-vars

class DeleteWebhook extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isSubmit: false
    };
  }

  handleSubmit() {
    const { dispatch, onClose, data } = this.props;

    this.setState({
      isSubmit: true
    });

    dispatch({
      type: DELETE_WEBHOOK.REQUEST,
      payload: {
        params: {
          workspaceId: data.workspaceId,
          webhookId: data.webhookId
        },
        callback: result => {
          if (result === 'error') {
            this.setState({
              isSubmit: false
            });
          } else {
            if (data.callbacks && data.callbacks.onSuccessSubmit) {
              data.callbacks.onSuccessSubmit();
            }
            onClose();
          }
        }
      }
    });
  }

  render() {
    const { visibility, onClose } = this.props;

    const { isSubmit } = this.state;
    const {
      data: { webhookUrl }
    } = this.props;
    return (
      <Modal visibility={visibility} onClose={onClose} styleName="sModal.modal__workspace">
        <div styleName="sModal.modal__header">
          <div styleName="sModal.modal__header__close">
            <TextButton title="Cancel" onClick={onClose} />
          </div>
          <div styleName="sModal.modal__header__title">Delete webhook</div>
        </div>
        <div styleName="sModal.modal__body">
          <ModalContent styleName="sModal.modal__content">
            <div>
              <div styleName="sModal.modal__description">
                Are you sure you want to delete webhook <strong>{webhookUrl}</strong>?<br />
                This action is irreversible.
              </div>
            </div>
          </ModalContent>
          <ModalButtons styleName="sModal.modal__buttons">
            <Button
              label="Delete"
              size="medium"
              onClick={this.handleSubmit.bind(this)}
              styleName={cn('sModal.modal__btn', 'sModal.red', 'sModal.wide', {
                red__disabled: isSubmit
              })}
              visibility={isSubmit ? 'disabled' : 'visible'}
              disabled={isSubmit}
            />
          </ModalButtons>
        </div>
      </Modal>
    );
  }
}

DeleteWebhook.propTypes = {
  visibility: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  data: PropTypes.object,
  dispatch: PropTypes.func
};

DeleteWebhook.contextTypes = {
  intl: intlShape
};

export default injectIntl(DeleteWebhook);
