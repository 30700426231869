/* eslint-disable no-confusing-arrow */
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { intlShape, injectIntl } from 'react-intl';
import { Modal, ModalButtons, Button, Icon } from 'mw-style-react';
import connect from 'react-redux/es/connect/connect';
import { MANAGE_USER_GROUPS, GET_GROUPS, GET_USER_GROUPS } from 'constants';
import AppUtils from '../../../../utils/utils';
import TextButton from '../../../TextButton';
import mes from './intl';
import GroupIcon from '../../../../images/group-icon.svg';
import sModal from '../../Modal.scss'; // eslint-disable-line no-unused-vars
import TagsInput from '../../../TagsInput/TagsInput';

class ManageUserGroups extends PureComponent {
  constructor(props) {
    super(props);
    const { data, dispatch } = props;
    this.state = {
      isSubmit: false,
      searchName: '',
      groupList: [],
      loading: true,
      selectedGroups: [],
      initialGroups: []
    };

    this.wrapperRef = React.createRef();
    this.usersInput = React.createRef();

    dispatch({
      type: GET_GROUPS.REQUEST,
      payload: {
        params: {
          workspaceId: data.workspaceId
        }
      }
    });

    dispatch({
      type: GET_USER_GROUPS.REQUEST,
      payload: {
        params: {
          workspaceId: data.workspaceId,
          userId: data.id
        },
        callback: (result, data) => {
          if (result === 'success' && data) {
            const transformed = data.map(d => ({ ...d, active: true }));
            this.setState({
              selectedGroups: transformed,
              initialGroups: transformed,
              loading: true
            });
          } else {
            this.setState({
              loading: false
            });
          }
        }
      }
    });
  }

  // Ф-ция локализации
  i(mesId, values) {
    return AppUtils.getMes(this.context)(mesId, values);
  }

  handleSubmit() {
    const { dispatch, onClose, data } = this.props;
    const { workspaceId, id } = data || {};
    const { selectedGroups = [], initialGroups = [] } = this.state;
    const deleted = initialGroups.filter(iu => {
      const selected = selectedGroups.find(e => e.id === iu.id) || {};
      return !selected.active;
    });
    const added = selectedGroups.filter(iu => {
      if (!iu.active) return false;
      const selected = initialGroups.find(e => e.id === iu.id);
      if (!selected) return true;
      return false;
    });
    const groups = [
      ...deleted.map(u => ({ id: u.id, active: false })),
      ...added.map(u => ({ id: u.id, active: true }))
    ];

    this.setState({
      isSubmit: true
    });

    if (!groups.length) {
      onClose();
      return;
    }

    dispatch({
      type: MANAGE_USER_GROUPS.REQUEST,
      payload: {
        params: { workspaceId, userId: id, final: selectedGroups.filter(r => r.active) },
        body: groups,
        callback: result => {
          if (result === 'error') {
            this.setState({
              isSubmit: false
            });
          } else {
            setTimeout(() => {
              onClose();
            }, 100);
          }
        }
      }
    });
  }

  toggleSelectGroup(role) {
    const handleToggle = state => {
      if (role.selected) {
        return state.selectedGroups.filter(u => u.id !== role.id);
      }
      return [...state.selectedGroups, role]
        .filter((value, index, self) => self.findIndex(v => v.id === value.id) === index)
        .map(u => (u.id !== role.id ? u : { ...u, active: true, selected: true }));
    };

    this.setState(state => ({
      ...state,
      selectedGroups: handleToggle(state)
    }));
  }

  handleRemoveItem(item) {
    const { id } = item || {};

    this.setState(state => ({
      ...state,
      selectedGroups: state.selectedGroups.map(u => (u.id !== id ? u : { ...u, active: false }))
    }));
  }

  handleOnChangeSearch(e) {
    const { value } = e.target;
    this.setState({
      searchName: value
    });
  }

  render() {
    const { visibility, onClose, data, groups } = this.props;

    const { list } = groups;

    const { searchName, isSubmit, selectedGroups } = this.state;
    const filteredselectedGroups = selectedGroups.filter(s => s.active);

    const filteredgroupList = list
      .filter(s => s.name.toLowerCase().startsWith(searchName.toLowerCase()))
      .map(u => ({
        ...u,
        selected: !!filteredselectedGroups.find(s => s.id === u.id)
      }));

    return (
      <Modal
        visibility={visibility}
        onClose={onClose}
        styleName="sModal.modal__workspace sModal.modal__overflowVisible"
      >
        <div styleName="sModal.modal__header">
          <div styleName="sModal.modal__header__close">
            <TextButton title="Cancel" onClick={onClose} />
          </div>
          <div styleName="sModal.modal__header__title">{this.i(mes.manageUserGroupsHeader)}</div>
        </div>
        <div styleName="sModal.modal__body">
          <div styleName="sModal.modal__section">
            <div styleName="sModal.modal__description">
              Select groups to assign for <strong>{data.name}</strong>
            </div>

            <div style={{ marginTop: '20px', position: 'relative' }}>
              <div styleName="sModal.modal__input__label has-gap">Add groups</div>
              <div ref={this.wrapperRef}>
                <TagsInput
                  ref={this.usersInput}
                  placeHolder="Search by name"
                  value={filteredselectedGroups.map(u => ({ id: u.id, name: u.name }))}
                  onSearch={this.handleOnChangeSearch.bind(this)}
                  onRemoved={this.handleRemoveItem.bind(this)}
                  name="users_role"
                  disabled={isSubmit}
                  loading={isSubmit}
                  disableBackspaceRemove
                  separators={[]}
                  addOnBlur={false}
                  view="block"
                />

                {!filteredgroupList.length && searchName ? null : (
                  <div styleName="sModal.modal__input__searchItems is-static">
                    <ul>
                      {filteredgroupList.length ? (
                        <>
                          {filteredgroupList.map(u => (
                            <li
                              key={u.id}
                              style={{ backgroundColor: u.selected ? '#F0F6FD' : 'transparent' }}
                              styleName="sModal.modal__input__searchItemControl-hovered"
                            >
                              <div
                                styleName="sModal.modal__input__searchItemControl"
                                role="button"
                                onClick={() => this.toggleSelectGroup(u)}
                              >
                                <span style={{ paddingRight: u.selected ? '24px' : 0 }}>
                                  {u.name}
                                </span>
                                {u.selected ? (
                                  <div styleName="sModal.modal__input__searchItemControl--selected">
                                    <Icon type="check" size="large" />
                                  </div>
                                ) : null}
                              </div>
                            </li>
                          ))}
                        </>
                      ) : (
                        <div styleName="sModal.modal__list__empty has-gap">
                          <GroupIcon />
                          <span>{this.i(mes.manageUserGroupsEmptyList)}</span>
                        </div>
                      )}
                    </ul>
                  </div>
                )}
              </div>

              {!filteredgroupList.length && searchName ? (
                <div styleName="sModal.modal__input__searchItems is-static">
                  <div styleName="modal__workspace__suggestion__list-no-data">
                    No results for &quot;{searchName}&quot;
                  </div>
                </div>
              ) : null}
            </div>
          </div>
          <ModalButtons styleName="sModal.modal__buttons">
            <Button
              label={this.i(mes.manageUserGroupsButton)}
              size="medium"
              onClick={this.handleSubmit.bind(this)}
              visibility={isSubmit ? 'disabled' : 'visible'}
              disabled={isSubmit}
              styleName="sModal.modal__btn sModal.wide"
            />
          </ModalButtons>
        </div>
      </Modal>
    );
  }
}

ManageUserGroups.propTypes = {
  visibility: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  data: PropTypes.object,
  groups: PropTypes.object,
  dispatch: PropTypes.func
};

ManageUserGroups.contextTypes = {
  intl: intlShape
};

const mapStateToProps = ({ groups }) => ({
  groups
});

export default injectIntl(connect(mapStateToProps)(ManageUserGroups));
