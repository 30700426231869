import { defineMessages } from 'react-intl';
import globalIntl from 'globalIntl'; // eslint-disable-line import/no-unresolved

const m = defineMessages({
  modifyWorkspace: {
    id: 'modifyWorkspace',
    defaultMessage: 'Edit workspace'
  },
  nameLabel: {
    id: 'nameLabel',
    defaultMessage: 'Name'
  },
  btnModify: {
    id: 'btnModify',
    defaultMessage: 'Save'
  }
});

export default { ...globalIntl, ...m };
