import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { intlShape, injectIntl } from 'react-intl';
import {
  Modal,
  ModalButtons,
  ModalContent,
  Button,
  ProgressBar,
  Label,
  TextField
} from 'mw-style-react';
import { OFF_2FA } from 'constants';
import AppUtils from '../../../../utils/utils';
import mes from './intl';
import sModal from '../../Modal.scss'; // eslint-disable-line no-unused-vars
import sLocal from './OffTwoFactorAuth.scss'; // eslint-disable-line no-unused-vars

class OffTwoFactorAuth extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      step: 1,
      qrCodeValue: '',
      qrCodeError: false,
      helperText: ''
    };
  }

  // Ф-ция локализации
  i(mesId, values) {
    return AppUtils.getMes(this.context)(mesId, values);
  }

  handleInputChange(field, name) {
    this.setState({
      [name]: field.value,
      qrCodeError: false,
      helperText: ''
    });
  }

  setStep(step) {
    if (step === 2) {
      this.props.dispatch({
        type: OFF_2FA.REQUEST,
        payload: this.state.qrCodeValue,
        callback: data => {
          if (data.result === 'error') {
            this.setStep(1);
            this.setState({
              qrCodeError: true,
              helperText: data.message || data.description || ''
            });
          } else {
            this.props.onClose();
          }
        }
      });
    }
  }

  renderContent(step) {
    switch (step) {
      case 1:
        return (
          <div styleName="sModal.modal__content sLocal.content">
            <div styleName="sLocal.center">
              <Label value="Turn off Two-Factor Authentication?" fontWeight="semibold" />
            </div>
            <div styleName="sLocal.center">
              <Label value="If you turn off Two-Factor Authentication, your account will be protected only with your password. Enter verification code to proceed" />
            </div>
            <div styleName="sLocal.center">
              <TextField
                value={this.state.qrCodeValue}
                onChange={e => this.handleInputChange(e, 'qrCodeValue')}
                bordered={true}
                error={this.state.qrCodeError}
                helperText={this.state.helperText}
              />
            </div>
          </div>
        );

      // case 2:
      //   return (
      //     <div styleName="sModal.modal__content sLocal.content">
      //       <div styleName="sLocal.center">
      //         <img src={authLike} alt={'auth'} width="100px" height="100px"/>
      //       </div>
      //       <div styleName="sLocal.center">
      //         <Label value={'Congratulations'} fontWeight={'semibold'}/>
      //       </div>
      //       <div style={{display: 'flex', flexDirection: 'column'}}>
      //       </div>
      //     </div>
      //   );
      default:
        return <div />;
    }
  }

  render() {
    const { visibility, onClose, isSubmit } = this.props;

    const { step } = this.state;

    return (
      <Modal
        styleName="sLocal.titleHead"
        visibility={visibility}
        onClose={onClose}
        label="Enable Two-factor authentication"
      >
        <ModalContent styleName="sModal.modal__content sLocal.content">
          {this.renderContent(step)}
        </ModalContent>
        <ModalButtons styleName="sModal.modal__buttons">
          {step === 1 && (
            <Button
              label={this.i(mes.cancel)}
              size="medium"
              type="text"
              onClick={onClose}
              visibility="visible"
            />
          )}
          <Button
            label="Finish"
            size="medium"
            onClick={() => this.setStep(step + 1)}
            visibility={isSubmit ? 'disabled' : 'visible'}
            disabled={isSubmit}
          />
          <ProgressBar
            styleName="sModal.modal__loader"
            type="circle"
            size="small"
            visibility={isSubmit ? 'visible' : 'hidden'}
          />
        </ModalButtons>
      </Modal>
    );
  }
}

OffTwoFactorAuth.propTypes = {
  visibility: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  isSubmit: PropTypes.bool,
  dispatch: PropTypes.func
};

OffTwoFactorAuth.contextTypes = {
  intl: intlShape
};

export default injectIntl(OffTwoFactorAuth);
