import { defineMessages } from 'react-intl';
import globalIntl from 'globalIntl'; // eslint-disable-line import/no-unresolved

const m = defineMessages({
  deleteWorkspace: {
    id: 'deleteWorkspace',
    defaultMessage: 'Delete workspace'
  },
  nameLabel: {
    id: 'nameLabel',
    defaultMessage: 'Name'
  },
  btnDelete: {
    id: 'btnDelete',
    defaultMessage: 'Delete'
  }
});

export default { ...globalIntl, ...m };
