import { defineMessages } from 'react-intl';
import globalIntl from 'globalIntl'; // eslint-disable-line import/no-unresolved

const m = defineMessages({
  modifyGroup: {
    id: 'modifyGroup',
    defaultMessage: 'Rename group'
  },
  groupNameLabelModify: {
    id: 'groupNameLabelModify',
    defaultMessage: 'Name'
  },
  btnModifyGroup: {
    id: 'btnModifyGroup',
    defaultMessage: 'Rename'
  }
});

export default { ...globalIntl, ...m };
