import { defineMessages } from 'react-intl';
import globalIntl from 'globalIntl'; // eslint-disable-line import/no-unresolved

const m = defineMessages({
  titleChangeRPS: {
    id: 'titleChangeRPS',
    defaultMessage: 'Change RPS'
  },
  btnChangeRPS: {
    id: 'btnChangeRPS',
    defaultMessage: 'Save'
  }
});

export default { ...globalIntl, ...m };
