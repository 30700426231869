import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Icon from '../../images/info.svg';

import './CustomBanner.scss';

function CustomBanner({ text, color }) {
  return (
    <div
      styleName={cn('custom__banner', {
        orange__color: color === 'orange'
      })}
    >
      <div styleName="custom__banner__icon">
        <Icon />
      </div>
      <div styleName="custom__banner__text">{text}</div>
    </div>
  );
}

CustomBanner.propTypes = {
  text: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired
};

export default CustomBanner;
