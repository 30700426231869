/* eslint-disable import/no-import-module-exports */
import { applyMiddleware, compose, createStore } from 'redux';
import { CLIENT } from 'constants';
import makeRootReducer, { injectReducer } from '../reducers/index';
import sagaMiddleware from '../sagas/runSaga';
import clientSaga from '../sagas/client';
import notify from '../sagas/notify';
import uploadFile from '../sagas/uploadFile';

export default (initialState = {}) => {
  // ======================================================
  // Store Enhancers
  // ======================================================
  const enhancers = [];

  let composeEnhancers = compose;

  if (__DEV__) {
    const composeWithDevToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
    if (typeof composeWithDevToolsExtension === 'function') {
      composeEnhancers = composeWithDevToolsExtension;
    }
  }

  // ======================================================
  // Инициализация Store
  // ======================================================
  const store = createStore(
    makeRootReducer(),
    initialState,
    composeEnhancers(applyMiddleware(sagaMiddleware), ...enhancers)
  );

  // ======================================================
  //  Запуск sagas, для прослушивания action.
  // ======================================================
  sagaMiddleware.run(clientSaga);
  sagaMiddleware.run(notify);
  sagaMiddleware.run(uploadFile);
  // ======================================================

  store.asyncReducers = {};
  store.runSaga = sagaMiddleware.run;
  store.injectReducer = injectReducer;

  // ======================================================
  // Запрос получения конфигурации при старте
  // ======================================================
  const { dispatch } = store;
  dispatch({ type: CLIENT.REQUEST });

  if (module.hot) {
    module.hot.accept('../reducers/index.js', () => {
      const reducers = require('../reducers/index').default;
      store.replaceReducer(reducers(store.asyncReducers));
    });
  }
  return store;
};
