/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { intlShape, injectIntl } from 'react-intl';
import { Modal, ModalButtons, Button, TextField, MenuItem, Select } from 'mw-style-react';
import connect from 'react-redux/es/connect/connect';
import { CREATE_INVITE, GET_AVAILABLE_ROLES } from 'constants';
import AppUtils from '../../../../utils/utils';
import TextButton from '../../../TextButton';
import mes from './intl';
import sModal from '../../Modal.scss'; // eslint-disable-line no-unused-vars

class InviteToWorkspace extends PureComponent {
  constructor(props) {
    super(props);
    const { dispatch, data } = props;
    this.state = {
      loading: true,
      isSubmit: false,
      email: '',
      role: null,
      errorEmail: false,
      errorRole: false
    };

    dispatch({
      type: GET_AVAILABLE_ROLES.REQUEST,
      payload: {
        params: {
          workspaceId: data.ext_id
        },
        callback: result => {
          if (result !== 'error') {
            this.setState({
              loading: false
            });
          }
        }
      }
    });
  }

  // Ф-ция локализации
  i(mesId, values) {
    return AppUtils.getMes(this.context)(mesId, values);
  }

  handleOnChange(e) {
    const { id, value } = e;
    const errors = {
      errorRole: false
    };

    if (id === 'email') {
      errors.errorEmail = !AppUtils.validateName(value);
    }

    this.setState({
      [id]: value,
      ...errors
    });
  }

  async handleSubmit() {
    const {
      dispatch,
      onClose,
      data: { ext_id: workspaceId }
    } = this.props;
    const { email, role } = this.state;

    const valid = email && email.length && AppUtils.validateName(email);

    if (!valid || !role) {
      this.setState({
        errorEmail: !valid,
        errorRole: !role
      });
      return;
    }

    this.setState({
      isSubmit: true
    });

    dispatch({
      type: CREATE_INVITE.REQUEST,
      payload: {
        params: { workspaceId },
        body: { login: email, role_id: Number(role) },
        callback: result => {
          if (result === 'error') {
            this.setState({
              isSubmit: false
            });
          } else {
            onClose();
          }
        }
      }
    });
  }

  render() {
    const { visibility, onClose, roles } = this.props;

    const { list = [] } = roles || {};

    const { isSubmit, errorRole, errorEmail, email, role, loading } = this.state;

    return (
      <Modal
        visibility={visibility}
        onClose={onClose}
        styleName="sModal.modal__workspace sModal.modal__overflowVisible"
        style={{ overflow: 'visible' }}
      >
        <div styleName="sModal.modal__header">
          <div styleName="sModal.modal__header__close">
            <TextButton title="Cancel" onClick={onClose} />
          </div>
          <div styleName="sModal.modal__header__title">{this.i(mes.inviteToWorkspace)}</div>
        </div>
        <div styleName="sModal.modal__body">
          <div styleName="sModal.modal__section">
            <div
              styleName="sModal.modal__description"
              dangerouslySetInnerHTML={{ __html: this.i(mes.inviteDescription) }}
            />
            <div>
              <div styleName="sModal.modal__input__label">{this.i(mes.inviteLoginsLabel)}</div>
              <TextField
                styleName="sModal.modal__input"
                value={email}
                placeholder={this.i(mes.inviteLoginsPlaceholder)}
                onChange={this.handleOnChange.bind(this)}
                length={255}
                bordered
                error={errorEmail}
                id="email"
                visibility={isSubmit ? 'disabled' : 'visible'}
              />
            </div>
            <div>
              <div styleName="sModal.modal__select__label">{this.i(mes.inviteRoleLabel)}</div>
              <Select
                styleName="sModal.modal__select"
                value={role}
                onChange={this.handleOnChange.bind(this)}
                placeholder={this.i(mes.inviteRolePlaceholder)}
                length={255}
                bordered
                id="role"
                error={errorRole}
                visibility={isSubmit ? 'disabled' : 'visible'}
              >
                {list && list.length && !loading
                  ? list.map(item => <MenuItem label={item.name} value={String(item.id)} />)
                  : null}
              </Select>
            </div>
          </div>
          <ModalButtons styleName="sModal.modal__buttons">
            <Button
              label={this.i(mes.btnInvite)}
              size="medium"
              onClick={this.handleSubmit.bind(this)}
              styleName="sModal.modal__btn sModal.wide"
              visibility={isSubmit || errorRole || errorEmail ? 'disabled' : 'visible'}
              disabled={isSubmit || errorRole || errorEmail}
            />
          </ModalButtons>
        </div>
      </Modal>
    );
  }
}

InviteToWorkspace.propTypes = {
  visibility: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  data: PropTypes.object,
  dispatch: PropTypes.func
};

InviteToWorkspace.contextTypes = {
  intl: intlShape
};

const mapStateToProps = ({ roles }) => ({
  roles
});

export default injectIntl(connect(mapStateToProps)(InviteToWorkspace));
