import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import cn from 'classnames';
import { useLocation, Outlet } from 'react-router-dom';
import Notify from '../../../components/Notify';
import Modal from '../../../components/Modal';
import '../../../styles/main.scss';

function MainLayout({ notify = {}, modal, theme, children = null }) {
  const location = useLocation();
  const isHiddenFooter = paths => paths.some(path => location.pathname.startsWith(path));
  const hideFooter = isHiddenFooter([
    '/workspace',
    '/profile',
    '/license',
    '/choose',
    '/upload_license',
    '/users',
    '/idp',
    '/identity_providers'
  ]);
  const year = new Date().getFullYear();

  return (
    <div className={`theme-${theme}`}>
      <div styleName="main">
        <div
          styleName={cn('main__wrapChildren', {
            hideFooter
          })}
        >
          <Outlet />
          {children}
        </div>
        <RenderNotify notify={notify} />
        {!hideFooter ? (
          <div styleName="main__footer">
            <div>{`${year} © Corezoid Inc. All rights reserved. Patent protected.`}</div>
          </div>
        ) : null}
      </div>
      <RenderModal modal={modal} />
    </div>
  );
}

function RenderNotify({ notify = {} }) {
  const items = notify.items || [];
  if (!items.length) return null;
  return <Notify {...notify} />;
}

RenderNotify.propTypes = {
  notify: PropTypes.object
};

function RenderModal({ modal }) {
  if (!modal) return null;
  return <Modal {...modal} />;
}

RenderModal.propTypes = {
  modal: PropTypes.object
};

const mapStateToProps = state => ({
  theme: state.theme,
  notify: state.notify,
  modal: state.modal
});

const mapDispatchToProps = dispatch => ({
  dispatch
});

MainLayout.propTypes = {
  theme: PropTypes.string,
  modal: PropTypes.object,
  notify: PropTypes.object.isRequired,
  children: PropTypes.node
};

export default connect(mapStateToProps, mapDispatchToProps)(MainLayout);
