import { combineReducers } from 'redux';
import location from './location';
import theme from './theme';
import notify from './notify';
import config from './config';
import client from './client';
import modal from './modal';

const makeRootReducer = asyncReducers =>
  combineReducers({
    theme,
    config,
    client,
    notify,
    location,
    modal,
    ...asyncReducers
  });

export const injectReducer = (store, key, reducer) => {
  if (Object.hasOwnProperty.call(store.asyncReducers, key)) return;

  store.asyncReducers[key] = reducer;
  store.replaceReducer(makeRootReducer(store.asyncReducers));
};

export default makeRootReducer;
