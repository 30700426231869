import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'mw-style-react';
import cn from 'classnames';
import CustomAvatar from '../CustomAvatar';
import './ImageUploader.scss';
import { getContrastColor } from '../../utils/helperFunctions';

const systemFormsIcons = {
  ActorFilters: 'filter',
  CommentsWidgets: 'form',
  Events: 'event',
  Graphs: 'graph',
  Layers: 'layer_filled',
  Locations: 'location',
  Scripts: 'form_pole',
  Snippets: 'snippet',
  Streams: 'stream',
  Tags: 'hash',
  Users: 'client',
  Widgets: 'widget'
};

/**
 * Аватар аплоадера со статусами
 * @returns {*}
 * @constructor
 */
function ImageUploader(props) {
  const {
    size = 'medium',
    formType,
    formTitle,
    icon = formType === 'system' ? systemFormsIcons[formTitle] : null,
    iconLabel,
    pictureUrl,
    color,
    colors = [],
    disabledLabel
  } = props;
  const actorColor = colors.find(i => i.type === 'actor') || {};
  const formColor = colors.find(i => i.type === 'form') || {};
  const mainColor = color || actorColor.color || formColor.color;
  const WHITE_COLOR = '#ffffff';
  const bgColor = mainColor || WHITE_COLOR;
  const textColor = getContrastColor(bgColor);
  return (
    <div styleName={cn('aa__wrap', size)}>
      <CustomAvatar size="xxlarge" color={bgColor} photo={pictureUrl}>
        {!pictureUrl && icon && !disabledLabel ? (
          <div styleName={cn('aa__iconWrap', { iconLabel: !!iconLabel })}>
            <Icon
              styleName={cn('aa__icon', { upload: icon === 'picture' })}
              size={size}
              type={icon}
              color={textColor}
            />
            {iconLabel ? <span style={{ color: textColor }}>{iconLabel}</span> : null}
          </div>
        ) : null}
        {!pictureUrl && disabledLabel && (
          <div style={{ color: textColor }} styleName="aa__label-text">
            {disabledLabel}
          </div>
        )}
      </CustomAvatar>
    </div>
  );
}

ImageUploader.propTypes = {
  size: PropTypes.oneOf(['micro', 'small', 'medium', 'large', 'xlarge', 'xxlarge']),
  iconLabel: PropTypes.string,
  formType: PropTypes.string,
  formTitle: PropTypes.string,
  icon: PropTypes.string,
  pictureUrl: PropTypes.string,
  color: PropTypes.string,
  colors: PropTypes.array,
  disabledLabel: PropTypes.string
};

export default ImageUploader;
