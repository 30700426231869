import PropTypes from 'prop-types';
import React from 'react';
import { Modal, ModalButtons, ModalContent, Button } from 'mw-style-react';
import sModal from '../../Modal.scss';

function ModifyWorkspaceOwnership({ visibility, onClose, isSubmit, data }) {
  const { content, callback } = data;

  const handleClick = () => {
    if (callback) {
      callback();
      onClose();
    }
  };

  return (
    <Modal visibility={visibility} onClose={onClose} label={content.title || ''}>
      <ModalContent styleName="sModal.modal__content sModal.modal__content--gap">
        {content.text}
      </ModalContent>
      <ModalButtons styleName="sModal.modal__buttons">
        <Button
          label={content.button}
          type={content.buttonType || 'primary'}
          size="medium"
          onClick={handleClick}
          visibility={isSubmit ? 'disabled' : 'visible'}
          disabled={isSubmit}
        />
      </ModalButtons>
    </Modal>
  );
}

ModifyWorkspaceOwnership.propTypes = {
  visibility: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  isSubmit: PropTypes.bool,
  data: PropTypes.object
};

export default ModifyWorkspaceOwnership;
