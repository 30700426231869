/* eslint-disable no-confusing-arrow */
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { intlShape, injectIntl } from 'react-intl';
import { Modal, ModalButtons, Avatar, Button, ProgressBar, Icon } from 'mw-style-react';
import connect from 'react-redux/es/connect/connect';
import {
  GET_GROUP_USERS,
  MODIFY_GROUP_USERS,
  SEARCH_USERS,
  GET_USERS_SUGGESTION,
  DEBOUNCE_MS
} from 'constants';
import InfiniteScroll from 'react-infinite-scroll-component';
import AppUtils from '../../../../utils/utils';
import TextButton from '../../../TextButton';
import mes from './intl';
import GroupIcon from '../../../../images/group-icon.svg';

import sModal from '../../Modal.scss'; // eslint-disable-line no-unused-vars
import { getContrastColor } from '../../../../utils/helperFunctions';
import TagsInput from '../../../TagsInput/TagsInput';

class ModifyGroupUsers extends PureComponent {
  constructor(props) {
    super(props);
    const { dispatch, data } = props;
    this.state = {
      isSubmit: false,
      searchName: '',
      userList: [],
      selectedUsers: [],
      initialUsers: [],
      hasMore: true,
      offset: 0,
      searchLoading: false
    };

    this.timer = null;
    this.listRef = React.createRef();
    this.wrapperRef = React.createRef();
    this.usersInput = React.createRef();

    dispatch({
      type: GET_GROUP_USERS.REQUEST,
      payload: {
        params: {
          workspaceId: data.workspaceId,
          groupId: data.id
        },
        callback: (result, data) => {
          const transformed = data.map(d => ({ ...d, active: true }));
          if (result === 'success' && data) {
            this.setState({
              selectedUsers: transformed,
              initialUsers: transformed
            });
          }
        }
      }
    });

    this.getSuggestion(data);
  }

  getSuggestion(data) {
    const { dispatch } = this.props;
    const { searchName } = this.state;

    dispatch({
      type: GET_USERS_SUGGESTION.REQUEST,
      payload: {
        params: {
          workspaceId: data.workspaceId,
          limit: 20,
          offset: 0,
          search: searchName,
          merge: true
        },
        callback: (result, data, hasMore) => {
          if (result === 'success' && data) {
            this.setState(prev => ({
              ...prev,
              userList: data,
              offset: 0,
              hasMore,
              searchLoading: false
            }));
          }
        }
      }
    });
  }

  fetchMore() {
    const { dispatch, data } = this.props;
    const { offset, searchName } = this.state;

    dispatch({
      type: GET_USERS_SUGGESTION.REQUEST,
      payload: {
        params: {
          workspaceId: data.workspaceId,
          limit: 20,
          offset: offset + 20,
          search: searchName,
          merge: true
        },
        callback: (result, data, hasMore) => {
          if (result === 'success' && data) {
            this.setState(prev => ({
              ...prev,
              userList: [...prev.userList, ...data],
              offset: prev.offset + 20,
              hasMore
            }));
          }
        }
      }
    });
  }

  // Ф-ция локализации
  i(mesId, values) {
    return AppUtils.getMes(this.context)(mesId, values);
  }

  handleSubmit() {
    const { dispatch, onClose, data, auth, workspace } = this.props;
    const { permissions } = workspace || {};
    const { allGroupManaging } = permissions || {};
    const { id: userId } = auth || {};

    const { workspaceId, id, owners } = data || {};
    const { selectedUsers = [], initialUsers = [] } = this.state;
    const activeList = selectedUsers.filter(r => r.active);
    const deleted = initialUsers.filter(iu => {
      const selected = selectedUsers.find(e => e.id === iu.id) || {};
      return !selected.active;
    });
    const added = selectedUsers.filter(iu => {
      if (!iu.active) return false;
      const selected = initialUsers.find(e => e.id === iu.id);
      if (!selected) return true;
      return false;
    });
    const users = [
      ...deleted.map(u => ({ ...u, active: false })),
      ...added.map(u => ({ ...u, active: true }))
    ];
    const isOwnerCurrentUser = owners ? owners.findIndex(el => el.id === userId) !== -1 : false;
    const currentUserDeleted = !!deleted.find(d => d.id === userId);

    this.setState({
      isSubmit: true
    });

    if (!users.length) {
      onClose();
      return;
    }

    dispatch({
      type: MODIFY_GROUP_USERS.REQUEST,
      payload: {
        params: {
          workspaceId,
          groupId: id,
          userCount: activeList.length,
          removeGroup: !allGroupManaging && !isOwnerCurrentUser && currentUserDeleted
        },
        body: users,
        callback: result => {
          if (result === 'error') {
            this.setState({
              isSubmit: false
            });
          } else {
            onClose();
          }
        }
      }
    });
  }

  toggleSelectUser(user) {
    const handleToggle = state => {
      if (user.selected) {
        return state.selectedUsers.map(u =>
          u.id !== user.id ? u : { ...u, active: false, selected: false }
        );
      }
      return [...state.selectedUsers, user]
        .filter((value, index, self) => self.findIndex(v => v.id === value.id) === index)
        .map(u => (u.id !== user.id ? u : { ...u, active: true, selected: true }));
    };

    this.setState(state => ({
      ...state,
      selectedUsers: handleToggle(state)
    }));
  }

  handleRemoveItem(item) {
    const { id } = item || {};

    this.setState(state => ({
      ...state,
      selectedUsers: state.selectedUsers.map(u => (u.id !== id ? u : { ...u, active: false }))
    }));
  }

  handleOnChangeSearch(e) {
    const { dispatch, data } = this.props;
    const { workspaceId } = data || {};
    const { value } = e.target;
    this.setState({
      searchName: value,
      offset: 0,
      hasMore: true,
      searchLoading: true
    });

    clearTimeout(this.timer);
    if (this.listRef.current) {
      this.listRef.current.scrollTop = 0;
    }

    this.timer = setTimeout(() => {
      if (value.length < 2) {
        this.getSuggestion(data);
      }

      if (value.length > 1) {
        dispatch({
          type: SEARCH_USERS.REQUEST,
          payload: {
            params: {
              workspaceId,
              query: value,
              limit: 20
            },
            callback: (result, data, hasMore) => {
              if (result === 'success' && data) {
                this.setState({
                  userList: data,
                  searchLoading: false,
                  hasMore
                });
              }
            }
          }
        });
      }
    }, DEBOUNCE_MS);
  }

  render() {
    const { visibility, onClose, data } = this.props;

    const { searchName, isSubmit, userList, selectedUsers, hasMore, searchLoading } = this.state;

    const filteredSelectedUsers = selectedUsers.filter(s => s.active);

    const filteredUserList = userList.map(u => ({
      ...u,
      selected: !!filteredSelectedUsers.find(s => s.id === u.id)
    }));

    return (
      <Modal
        visibility={visibility}
        onClose={onClose}
        styleName="sModal.modal__workspace sModal.modal__overflowVisible"
      >
        <div styleName="sModal.modal__header">
          <div styleName="sModal.modal__header__close">
            <TextButton title="Cancel" onClick={onClose} />
          </div>
          <div styleName="sModal.modal__header__title">{this.i(mes.modifyGroupUsersHeader)}</div>
        </div>
        <div styleName="sModal.modal__body">
          <div styleName="sModal.modal__section">
            <div styleName="sModal.modal__description">
              Select users to assign for <strong>{data.name}</strong> group.
            </div>

            <div style={{ marginTop: '20px', position: 'relative' }}>
              <div styleName="sModal.modal__input__label">Add user</div>
              <div ref={this.wrapperRef}>
                <TagsInput
                  ref={this.usersInput}
                  placeHolder="Search by name or email"
                  value={filteredSelectedUsers.map(u => ({ id: u.id, name: u.name }))}
                  onSearch={this.handleOnChangeSearch.bind(this)}
                  onRemoved={this.handleRemoveItem.bind(this)}
                  name="users_role"
                  disabled={isSubmit}
                  loading={isSubmit}
                  disableBackspaceRemove
                  separators={[]}
                  addOnBlur={false}
                  view="block"
                />
                {!filteredUserList.length && searchName ? null : (
                  <div styleName="sModal.modal__input__searchItems is-static">
                    <ul id="scrollableModal" ref={this.listRef}>
                      <InfiniteScroll
                        dataLength={filteredUserList.length}
                        next={this.fetchMore.bind(this)}
                        hasMore={hasMore}
                        style={{ overflow: 'visible' }}
                        loader={
                          <div styleName="modal__workspace__suggestion__loader">
                            <ProgressBar />
                          </div>
                        }
                        scrollableTarget="scrollableModal"
                      >
                        {searchLoading && (
                          <div styleName="modal__workspace__suggestion__list-loader">
                            <ProgressBar />
                          </div>
                        )}
                        {filteredUserList.length ? (
                          <>
                            {filteredUserList.map(u => (
                              <li
                                key={u.id}
                                style={{ backgroundColor: u.selected ? '#F0F6FD' : 'transparent' }}
                                styleName="sModal.modal__input__searchItemControl-hovered"
                              >
                                <div
                                  styleName="sModal.modal__input__searchItemControl"
                                  role="button"
                                  onClick={() => this.toggleSelectUser(u)}
                                >
                                  <Avatar
                                    styleName="modal__small__avatar"
                                    size="small"
                                    src={u.photo}
                                    bgColor={u.color}
                                    label={u.name}
                                    textColor={getContrastColor(u.color)}
                                  />
                                  <span style={{ paddingRight: u.selected ? '24px' : 0 }}>
                                    {u.name}
                                  </span>
                                  {u.selected ? (
                                    <div styleName="sModal.modal__input__searchItemControl--selected">
                                      <Icon type="check" size="large" />
                                    </div>
                                  ) : null}
                                </div>
                              </li>
                            ))}
                          </>
                        ) : searchName || searchLoading ? null : (
                          <div styleName="sModal.modal__list__empty has-gap">
                            <GroupIcon />
                            <span>{this.i(mes.modifyGroupUsersEmptyList)}</span>
                          </div>
                        )}
                      </InfiniteScroll>
                    </ul>
                  </div>
                )}
              </div>

              {!filteredUserList.length && searchName ? (
                <div styleName="sModal.modal__input__searchItems is-static">
                  {searchLoading ? (
                    <div styleName="modal__workspace__suggestion__list-loader is-solid">
                      <ProgressBar />
                    </div>
                  ) : (
                    <div styleName="modal__workspace__suggestion__list-no-data">
                      No results for &quot;{searchName}&quot;
                    </div>
                  )}
                </div>
              ) : null}
            </div>
          </div>
          <ModalButtons styleName="sModal.modal__buttons">
            <Button
              label={this.i(mes.modifyGroupUsersButton)}
              size="medium"
              onClick={this.handleSubmit.bind(this)}
              visibility={isSubmit ? 'disabled' : 'visible'}
              disabled={isSubmit}
              styleName="sModal.modal__btn sModal.wide"
            />
          </ModalButtons>
        </div>
      </Modal>
    );
  }
}

ModifyGroupUsers.propTypes = {
  visibility: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  data: PropTypes.object,
  auth: PropTypes.object,
  workspace: PropTypes.object,
  dispatch: PropTypes.func
};

ModifyGroupUsers.contextTypes = {
  intl: intlShape
};

const mapStateToProps = ({ workspace }) => ({
  workspace
});

export default injectIntl(connect(mapStateToProps)(ModifyGroupUsers));
