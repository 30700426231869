import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { Notify } from 'mw-style-react';

import './Notify.scss';
import { HIDE_NOTIFY } from '../../constants';

class NotifyApp extends PureComponent {
  handleItemClose(id) {
    const { dispatch } = this.props;
    dispatch({ type: HIDE_NOTIFY.REQUEST, payload: { id } });
  }

  render() {
    const { items } = this.props;
    const transformedItems = items.length
      ? items.map(item => ({
          ...item,
          type: item.type === 'warning' ? 'error' : item.type,
          className: item.type === 'warning' ? 'notify__item__warning' : ''
        }))
      : items;

    if (!items.length) return null;

    return (
      <div>
        <Notify
          styleName="notification__items"
          value={transformedItems}
          onClose={this.handleItemClose.bind(this)}
        />
      </div>
    );
  }
}

NotifyApp.propTypes = {
  items: PropTypes.array.isRequired,
  dispatch: PropTypes.func.isRequired
};
const mapDispatchToProps = dispatch => ({
  dispatch
});
const mapStateToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(NotifyApp);
