import { defineMessages } from 'react-intl';
import globalIntl from 'globalIntl'; // eslint-disable-line import/no-unresolved

const m = defineMessages({
  modifyGroupUsersHeader: {
    id: 'modifyGroupUsersHeader',
    defaultMessage: 'Assign to group'
  },
  modifyGroupUsersButton: {
    id: 'modifyGroupUsersButton',
    defaultMessage: 'Save'
  },
  modifyGroupUsersEmptyList: {
    id: 'modifyGroupUsersEmptyList',
    defaultMessage: 'There are no users yet in this group.'
  }
});

export default { ...globalIntl, ...m };
