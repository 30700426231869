export const sortPages = {
  licenseList: { sort: null, order: null },
  licensePayments: { sort: null, order: null },
  licenseUsers: { sort: null, order: null },
  users: { sort: null, order: null },
  invites: { sort: null, order: null },
  groups: { sort: null, order: null },
  apiKeys: { sort: null, order: null },
  groupsUsers: { sort: null, order: null },
  groupsApiKeys: { sort: null, order: null }
};

export const updateSortVault = (key, newData) => {
  try {
    const existingData = localStorage.getItem('sortPages');
    let mergedData = existingData ? JSON.parse(existingData) : {};
    mergedData = {
      ...sortPages,
      ...mergedData,
      [key]: newData
    };

    localStorage.setItem('sortPages', JSON.stringify(mergedData));
  } catch (error) {
    console.error('Error updating local storage object:', error);
  }
};

export const readSortVault = key => {
  try {
    const scheme = localStorage.getItem('sortPages');
    const value = scheme ? JSON.parse(scheme) : sortPages;
    return value ? value[key] : null;
  } catch (error) {
    console.error('Error reading from local storage:', error);
    return null;
  }
};

export const addDetailsVault = key => {
  try {
    const existingData = localStorage.getItem('showDetails');
    let mergedData = existingData ? JSON.parse(existingData) : {};
    mergedData = {
      ...mergedData,
      [key]: true
    };

    localStorage.setItem('showDetails', JSON.stringify(mergedData));
  } catch (error) {
    console.error('Error updating local storage object:', error);
  }
};

export const getDetailsVault = () => {
  try {
    const scheme = localStorage.getItem('showDetails');
    const value = scheme ? JSON.parse(scheme) : {};
    return value;
  } catch (error) {
    console.error('Error reading from local storage:', error);
    return null;
  }
};

export const removeDetailsVault = key => {
  try {
    const scheme = localStorage.getItem('showDetails');
    const value = scheme ? JSON.parse(scheme) : null;
    delete value[key];
    localStorage.setItem('showDetails', JSON.stringify(value));
  } catch (error) {
    console.error('Error reading from local storage:', error);
    return null;
  }
};

export const removeDetailsVaultAll = () => {
  try {
    localStorage.removeItem('showDetails');
  } catch (error) {
    console.error('Error reading from local storage:', error);
    return null;
  }
};

export const getSessionBackUri = () => {
  try {
    const uri = sessionStorage.getItem('backUrl');
    return uri;
  } catch (error) {
    console.error('Error reading from session storage:', error);
    return null;
  }
};

export const setSessionBackUri = url => {
  try {
    sessionStorage.setItem('backUrl', url);
  } catch (error) {
    console.error('Error updating session storage:', error);
  }
};

export const removeSessionBackUri = () => {
  try {
    sessionStorage.removeItem('backUrl');
  } catch (error) {
    console.error('Error reading from session storage:', error);
    return null;
  }
};

export const getSessionTimestampProfileImage = () => {
  try {
    const timestamp = localStorage.getItem('profileImageTimestamp');
    return timestamp;
  } catch (error) {
    console.error('Error reading from local storage:', error);
    return null;
  }
};

export const addSessionTimestampProfileImage = (forceUpdate = false) => {
  try {
    if (!getSessionTimestampProfileImage() || forceUpdate) {
      localStorage.setItem('profileImageTimestamp', Math.floor(Date.now() / 1000));
    }
  } catch (error) {
    console.error('Error updating local storage:', error);
  }
};
