/* eslint-disable no-confusing-arrow */
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { intlShape, injectIntl } from 'react-intl';
import { Modal, ModalButtons, Button, Icon } from 'mw-style-react';
import connect from 'react-redux/es/connect/connect';
import { MANAGE_USER_ROLES, GET_ROLES } from 'constants';
import AppUtils from '../../../../utils/utils';
import TextButton from '../../../TextButton';
import mes from './intl';

import sModal from '../../Modal.scss'; // eslint-disable-line no-unused-vars
import TagsInput from '../../../TagsInput/TagsInput';

class ManageUserRoles extends PureComponent {
  constructor(props) {
    super(props);
    const { data, dispatch } = props;
    this.state = {
      isSubmit: false,
      searchName: '',
      roleList: [],
      selectedRoles: [...data.roles.map(r => ({ ...r, active: true }))],
      initialRoles: [...data.roles.map(r => ({ ...r, active: true }))]
    };

    this.wrapperRef = React.createRef();
    this.usersInput = React.createRef();

    dispatch({
      type: GET_ROLES.REQUEST,
      payload: {
        params: {
          workspaceId: data.workspaceId
        }
      }
    });
  }

  // Ф-ция локализации
  i(mesId, values) {
    return AppUtils.getMes(this.context)(mesId, values);
  }

  handleSubmit() {
    const { dispatch, onClose, data } = this.props;
    const { workspaceId, id } = data || {};
    const { selectedRoles = [], initialRoles = [] } = this.state;
    const deleted = initialRoles.filter(iu => {
      const selected = selectedRoles.find(e => e.id === iu.id) || {};
      return !selected.active;
    });
    const added = selectedRoles.filter(iu => {
      if (!iu.active) return false;
      const selected = initialRoles.find(e => e.id === iu.id);
      if (!selected) return true;
      return false;
    });
    const roles = [
      ...deleted.map(u => ({ id: u.id, active: false })),
      ...added.map(u => ({ id: u.id, active: true }))
    ];

    this.setState({
      isSubmit: true
    });

    if (!roles.length) {
      onClose();
      return;
    }

    dispatch({
      type: MANAGE_USER_ROLES.REQUEST,
      payload: {
        params: { workspaceId, userId: id, final: selectedRoles.filter(r => r.active) },
        body: roles,
        callback: result => {
          if (result === 'error') {
            this.setState({
              isSubmit: false
            });
          } else {
            setTimeout(() => {
              onClose();
            }, 100);
          }
        }
      }
    });
  }

  toggleSelectRole(role) {
    const handleToggle = state => {
      if (role.selected) {
        return state.selectedRoles.map(u =>
          u.id !== role.id ? u : { ...u, active: false, selected: false }
        );
      }
      return [...state.selectedRoles, role]
        .filter((value, index, self) => self.findIndex(v => v.id === value.id) === index)
        .map(u => (u.id !== role.id ? u : { ...u, active: true, selected: true }));
    };

    this.setState(state => ({
      ...state,
      selectedRoles: handleToggle(state)
    }));
  }

  handleRemoveItem(item) {
    const { id } = item || {};

    this.setState(state => ({
      ...state,
      selectedRoles: state.selectedRoles.map(u => (u.id !== id ? u : { ...u, active: false }))
    }));
  }

  handleOnChangeSearch(e) {
    const { value } = e.target;
    this.setState({
      searchName: value
    });
  }

  render() {
    const { visibility, onClose, data, roles } = this.props;

    const { list } = roles;

    const { searchName, isSubmit, selectedRoles } = this.state;
    const filteredSelectedRoles = selectedRoles.filter(s => s.active);

    const filteredRoleList = list
      .filter(s => s.name.toLowerCase().startsWith(searchName.toLowerCase()))
      .map(u => ({
        ...u,
        selected: !!filteredSelectedRoles.find(s => s.id === u.id)
      }));

    return (
      <Modal
        visibility={visibility}
        onClose={onClose}
        styleName="sModal.modal__workspace sModal.modal__overflowVisible"
      >
        <div styleName="sModal.modal__header">
          <div styleName="sModal.modal__header__close">
            <TextButton title="Cancel" onClick={onClose} />
          </div>
          <div styleName="sModal.modal__header__title">{this.i(mes.manageUserRolesHeader)}</div>
        </div>
        <div styleName="sModal.modal__body">
          <div styleName="sModal.modal__section">
            <div styleName="sModal.modal__description">
              Select roles to assign for <strong>{data.name}</strong>
            </div>

            <div style={{ marginTop: '20px', position: 'relative' }}>
              <div styleName="sModal.modal__input__label">Add roles</div>
              <div ref={this.wrapperRef}>
                <TagsInput
                  ref={this.usersInput}
                  placeHolder="Search by name"
                  value={filteredSelectedRoles.map(u => ({ id: u.id, name: u.name }))}
                  onSearch={this.handleOnChangeSearch.bind(this)}
                  onRemoved={this.handleRemoveItem.bind(this)}
                  name="users_roles"
                  disabled={isSubmit}
                  loading={isSubmit}
                  disableBackspaceRemove
                  separators={[]}
                  addOnBlur={false}
                  view="block"
                />
                {!filteredRoleList.length && searchName ? null : (
                  <div styleName="sModal.modal__input__searchItems is-static">
                    <ul>
                      {filteredRoleList.length ? (
                        <>
                          {filteredRoleList.map(u => (
                            <li
                              key={u.id}
                              style={{ backgroundColor: u.selected ? '#F0F6FD' : 'transparent' }}
                              styleName="sModal.modal__input__searchItemControl-hovered"
                            >
                              <div
                                styleName="sModal.modal__input__searchItemControl"
                                role="button"
                                onClick={() => this.toggleSelectRole(u)}
                              >
                                <span style={{ paddingRight: u.selected ? '24px' : 0 }}>
                                  {u.name}
                                </span>
                                {u.selected ? (
                                  <div styleName="sModal.modal__input__searchItemControl--selected">
                                    <Icon type="check" size="large" />
                                  </div>
                                ) : null}
                              </div>
                            </li>
                          ))}
                        </>
                      ) : null}
                    </ul>
                  </div>
                )}
              </div>

              {!filteredSelectedRoles.length && !searchName ? (
                <div styleName="sModal.modal__list__empty has-gap">
                  <span styleName="sModal.modal__list__empty__danger">
                    {this.i(mes.manageUserRolesEmptyList1)} <br />
                    {this.i(mes.manageUserRolesEmptyList2)}
                  </span>
                </div>
              ) : null}

              {!filteredRoleList.length && searchName ? (
                <div styleName="sModal.modal__input__searchItems is-static">
                  <div styleName="modal__workspace__suggestion__list-no-data">
                    No results for &quot;{searchName}&quot;
                  </div>
                </div>
              ) : null}
            </div>
          </div>
          <ModalButtons styleName="sModal.modal__buttons">
            <Button
              label={this.i(mes.manageUserRolesButton)}
              size="medium"
              onClick={this.handleSubmit.bind(this)}
              visibility={!filteredSelectedRoles.length || isSubmit ? 'disabled' : 'visible'}
              disabled={!filteredSelectedRoles.length || isSubmit}
              styleName="sModal.modal__btn sModal.wide"
            />
          </ModalButtons>
        </div>
      </Modal>
    );
  }
}

ManageUserRoles.propTypes = {
  visibility: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  data: PropTypes.object,
  roles: PropTypes.object,
  dispatch: PropTypes.func
};

ManageUserRoles.contextTypes = {
  intl: intlShape
};

const mapStateToProps = ({ roles }) => ({
  roles
});

export default injectIntl(connect(mapStateToProps)(ManageUserRoles));
