import { defineMessages } from 'react-intl';
import globalIntl from 'globalIntl'; // eslint-disable-line import/no-unresolved

const m = defineMessages({
  leaveWorkspace: {
    id: 'leaveWorkspace',
    defaultMessage: 'Leave workspace'
  },
  btnLeave: {
    id: 'btnLeave',
    defaultMessage: 'Leave'
  },
  leaveWorkspaceLabel: {
    id: 'leaveWorkspaceLabel',
    defaultMessage: 'Add new owner'
  },
  bannerCautionLeave: {
    id: 'bannerCautionLeave',
    defaultMessage: 'Groups and API keys will be transferred to the owner of the workspace.'
  }
});

export default { ...globalIntl, ...m };
