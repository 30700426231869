import { useContext } from 'react';
import { IntlContext } from '../containers/IntlProvider';
import AppUtils from '../utils/utils';

/**
 * Хук с контекстом локализации приложения
 */
const useIntl = () => {
  const intl = useContext(IntlContext);
  return (mesId, values = {}, bbcode = true) => AppUtils.intlParse({ intl, mesId, values, bbcode });
};

export default useIntl;
