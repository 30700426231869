import { defineMessages } from 'react-intl';
import globalIntl from 'globalIntl'; // eslint-disable-line import/no-unresolved

const m = defineMessages({
  titleDeleteRole: {
    id: 'titleDeleteRole',
    defaultMessage: 'Delete role'
  },
  btnDeleteRole: {
    id: 'btnDeleteRole',
    defaultMessage: 'Delete'
  }
});

export default { ...globalIntl, ...m };
