import { SHOW_NOTIFY, HIDE_NOTIFY } from 'constants';

// ------------------------------------
// Reducer
// ------------------------------------

export default function notifyReducer(state = { items: [] }, action) {
  switch (action.type) {
    case HIDE_NOTIFY.SUCCESS:
    case SHOW_NOTIFY.SUCCESS:
      return action.payload;
    default:
      return state;
  }
}
