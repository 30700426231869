import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import './LicenseAttributes.scss';

function LicenseAttributes({ data }) {
  if (!data) return null;

  const { rows = [], columns = [] } = data;
  const isSingleColumn = columns.length === 1;

  return (
    <div styleName="LicenseAttributes">
      <div
        styleName={cn('LicenseAttributes__head', {
          'is-sticky': !isSingleColumn,
          'is-single': isSingleColumn
        })}
      >
        <div styleName="LicenseAttributes__row is-head">
          <div styleName="LicenseAttributes__cell">Name</div>
          {columns.map((column, index) => (
            <div styleName="LicenseAttributes__cell" key={index}>
              {column}
            </div>
          ))}
        </div>
      </div>
      <div styleName="LicenseAttributes__body">
        {rows.map((row, index) => (
          <div
            styleName={cn('LicenseAttributes__row', {
              'is-single': isSingleColumn
            })}
            key={index}
          >
            <div styleName="LicenseAttributes__cell">{row.title}</div>
            {row.values.map((value, index) => (
              <div
                styleName={cn('LicenseAttributes__cell', {
                  highlight: row.isEqual === false,
                  regular: row.isEqual === null
                })}
                key={index}
              >
                <span styleName="LicenseAttributes__cell-value">{value}</span>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}

LicenseAttributes.propTypes = {
  data: PropTypes.object
};

export default LicenseAttributes;
