import { defineMessages } from 'react-intl';
import globalIntl from 'globalIntl'; // eslint-disable-line import/no-unresolved

const m = defineMessages({
  titleDeleteApiKey: {
    id: 'titleDeleteApiKey',
    defaultMessage: 'Delete API Key'
  },
  btnDeleteApiKey: {
    id: 'btnDeleteApiKey',
    defaultMessage: 'Delete'
  }
});

export default { ...globalIntl, ...m };
