import { defineMessages } from 'react-intl';
import globalIntl from 'globalIntl'; // eslint-disable-line import/no-unresolved

const m = defineMessages({
  newApiKey: {
    id: 'newApiKey',
    defaultMessage: 'New API Key'
  },
  apiKeyWebhookNamePlaceholder: {
    id: 'apiKeyWebhookNamePlaceholder',
    defaultMessage: 'https://www.corezoid.com'
  },
  apiKeyWebhookNameLabel1: {
    id: 'apiKeyWebhookNameLabel1',
    defaultMessage: 'Webhook URL'
  },
  apiKeyWebhookNameLabel2: {
    id: 'apiKeyWebhookNameLabel2',
    defaultMessage: '(For receiving events from shared objects)'
  },
  apiKeyNamePlaceholder: {
    id: 'apiKeyNamePlaceholder',
    defaultMessage: 'Example: API robot v.2'
  },
  apiKeyNameLabel1: {
    id: 'apiKeyNameLabel1',
    defaultMessage: 'Name'
  },
  apiKeyScopesLabel1: {
    id: 'apiKeyScopesLabel1',
    defaultMessage: 'Scopes'
  },
  apiKeyScopesLabel2: {
    id: 'apiKeyScopesLabel2',
    defaultMessage: '(Select one or more scopes for the API key)'
  },
  btnCreateApiKey: {
    id: 'btnCreateApiKey',
    defaultMessage: 'Create'
  }
});

export default { ...globalIntl, ...m };
