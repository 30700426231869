import { defineMessages } from 'react-intl';
import globalIntl from 'globalIntl'; // eslint-disable-line import/no-unresolved

const m = defineMessages({
  newGroup: {
    id: 'newGroup',
    defaultMessage: 'New group'
  },
  groupNewLabel: {
    id: 'groupNewLabel',
    defaultMessage: 'Name'
  },
  btnCreateGroup: {
    id: 'btnCreateGroup',
    defaultMessage: 'Create'
  },
  createGroupUsersEmptyList: {
    id: 'createGroupUsersEmptyList',
    defaultMessage: 'There are no users yet in this group.'
  }
});

export default { ...globalIntl, ...m };
