import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Routes, Route, unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';
import MainLayout from './MainLayout/MainLayout';
import Loader from '../../components/Loader/Loader';
import 'react-tippy/dist/tippy.css';
import '../../styles/core.scss';

const Workspace = React.lazy(() => import('../../routes/Workspace'));
const Enter = React.lazy(() => import('../../routes/Enter'));
const Settings = React.lazy(() => import('../../routes/Settings'));
const Main = React.lazy(() => import('../../routes/Main'));
const NotFound = React.lazy(() => import('../../routes/NotFound'));

function Suspense({ children }) {
  return <React.Suspense fallback={<Loader />}>{children}</React.Suspense>;
}

function RootLayout({ config, history }) {
  if (!Object.keys(config).length) return null;

  return (
    <HistoryRouter history={history}>
      <Routes>
        <Route path="/" element={<MainLayout />}>
          <Route
            index
            element={
              <Suspense>
                <Main />
              </Suspense>
            }
          />
          <Route
            path="/not_found"
            element={
              <Suspense>
                <NotFound />
              </Suspense>
            }
          />
          <Route
            path="/enter/:action/:hash?"
            element={
              <Suspense>
                <Enter />
              </Suspense>
            }
          />
          <Route
            path="/workspace/:workspaceId?/:category?/:itemId?/:tab?"
            element={
              <Suspense>
                <Workspace />
              </Suspense>
            }
          />
          <Route
            path="/choose/:status"
            element={
              <Suspense>
                <Workspace />
              </Suspense>
            }
          />
          <Route
            path="/:page/:action?/:id?/:tab?/:subtab?"
            element={
              <Suspense>
                <Settings />
              </Suspense>
            }
          />
          <Route
            path="*"
            element={
              <Suspense>
                <NotFound />
              </Suspense>
            }
          />
        </Route>
      </Routes>
    </HistoryRouter>
  );
}

RootLayout.propTypes = {
  config: PropTypes.object,
  history: PropTypes.object
};

Suspense.propTypes = {
  children: PropTypes.node
};

const mapStateToProps = state => ({
  config: state.config
});

export default connect(mapStateToProps)(RootLayout);
