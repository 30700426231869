import { defineMessages } from 'react-intl';
import globalIntl from 'globalIntl'; // eslint-disable-line import/no-unresolved

const m = defineMessages({
  titleDeleteInvite: {
    id: 'titleDeleteInvite',
    defaultMessage: 'Remove invite'
  },
  btnDeleteInvite: {
    id: 'btnDeleteInvite',
    defaultMessage: 'Remove'
  }
});

export default { ...globalIntl, ...m };
