import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { IntlProvider } from 'react-intl';
import { Provider } from 'react-redux';
import { ThemeProvider, CorezoidLightTheme } from 'mw-style-react';
import { IntlProviderWrapper } from './IntlProvider';
import RootLayout from './layouts/RootLayout';

/**
 * Главный контейнер
 */
class AppContainer extends Component {
  shouldComponentUpdate() {
    return false;
  }

  render() {
    const { store, messages, locale, history } = this.props;
    return (
      <Provider store={store}>
        <IntlProvider locale={locale} messages={messages}>
          <IntlProviderWrapper locale={locale} messages={messages}>
            <ThemeProvider theme={CorezoidLightTheme}>
              <RootLayout history={history} />
            </ThemeProvider>
          </IntlProviderWrapper>
        </IntlProvider>
      </Provider>
    );
  }
}

AppContainer.propTypes = {
  messages: PropTypes.object.isRequired,
  store: PropTypes.object.isRequired,
  locale: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired
};

export default AppContainer;
