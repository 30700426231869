import { workspaceStatus } from '../constants';
import AppUtils from './utils';

export const splitName = (arr = []) => {
  const names = arr.map(a => a.name);
  return names.join(', ');
};

// eslint-disable-next-line no-confusing-arrow
export const cutName = (str, length) =>
  str.length > length ? `${str.slice(0, length - 3)}...` : str;

export const getUniqueBy = (arr, prop) => {
  const set = new Set();
  return arr.filter(o => !set.has(o[prop]) && set.add(o[prop]));
};

export function allAreTruthy(arr) {
  return arr.every(element => element);
}

export const copyToClipboard = async text => {
  if (!(navigator && navigator.clipboard && navigator.clipboard.writeText)) {
    const el = document.createElement('textarea');
    el.value = text;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    try {
      if (document.execCommand('copy')) {
        document.body.removeChild(el);
        return true;
      }
      document.body.removeChild(el);
      return false;
    } catch (err) {
      document.body.removeChild(el);
      return false;
    }
  }

  try {
    await navigator.clipboard.writeText(text);
    return true;
  } catch (error) {
    return false;
  }
};

export const getMobileOS = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  if (/android/i.test(userAgent)) {
    return 'Android';
  }

  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return 'iOS';
  }

  return 'unknown';
};

export const unique = (arr, key) => {
  const keys = new Set();
  return arr.filter(el => !keys.has(el[key]) && keys.add(el[key]));
};

export const formatNumberToCurrency = number => {
  if (!number) {
    return '—';
  }
  const strNumber = number.toString();

  const parts = strNumber.split('.');
  let integerPart = parts[0];
  let decimalPart = parts[1] || '';

  integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

  if (decimalPart.length < 1) {
    decimalPart += '00';
  } else if (decimalPart.length < 2) {
    decimalPart += '0';
  } else if (decimalPart.length > 2) {
    decimalPart = decimalPart.slice(0, 2);
  }

  return `$${integerPart}.${decimalPart}`;
};

export const formatNumberWithWhitespaces = number =>
  number ? number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') : '0';

export const formatNumericValue = number =>
  number
    ? number
        .toString()
        .replace(/\D+/g, '')
        .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    : '0';

export function formatNumberToMillions(number) {
  if (number >= 1000000) {
    const millions = (number / 1000000).toFixed(1);
    return `${millions} M`;
  }

  if (!number) return '0';

  return '<1 M';
}

export const isNumber = value => {
  return typeof value === 'number' && !isNaN(value);
};

export const capitalizeFirstCharacter = str => str.charAt(0).toUpperCase() + str.slice(1);

export const parseEndValue = inputString => {
  const parts = inputString.split('.');
  if (parts.length > 1) {
    return parts[parts.length - 1];
  }
  return null;
};

export function flatten(arr) {
  return arr.reduce((acc, cur) => acc.concat(Array.isArray(cur) ? flatten(cur) : cur), []);
}

export function getCountableTitle({ data, title }) {
  return `${title} (${data || 0})`;
}

export function sortByPermGroups(arr, permGroups) {
  return arr.sort((a, b) => {
    const indexA = Object.keys(permGroups).indexOf(a);
    const indexB = Object.keys(permGroups).indexOf(b);
    return indexA - indexB;
  });
}

export function filterWorkspaces({ data, userId }) {
  return data.filter(workspace => {
    const isOwner = workspace.owners.some(owner => owner.id === userId);
    const { user_status: userStatus, status } = workspace;
    const isBlockedInWorkspace = userStatus !== workspaceStatus.blocked;
    const isBlockedWorkspace = status !== workspaceStatus.blocked;
    return isBlockedInWorkspace && (isBlockedWorkspace || isOwner);
  });
}

export function formatPeriod(data) {
  if (!data) return '—';
  if (data) return data === 1 ? 'Monthly' : 'Annually';
}

export function decoratePermId(id, type) {
  return `${type}-${id}`;
}

export function getContrastColor(bgColor = '') {
  const bgColorDefault = '#bad5f8';
  const colorDefault = '#1973E8';

  if (!bgColor) return colorDefault;
  if (bgColor.toLowerCase() === bgColorDefault) return colorDefault;

  const r = parseInt(bgColor.substr(1, 2), 16);
  const g = parseInt(bgColor.substr(3, 2), 16);
  const b = parseInt(bgColor.substr(5, 2), 16);
  const luminance = (0.2126 * r + 0.7152 * g + 0.0722 * b) / 255;

  return luminance > 0.5 ? '#000000' : '#FFFFFF';
}

export function getInitials(label) {
  if (!label) return '';
  return label
    .trim()
    .split(/\s+/)
    .map(n => (n[0] ? n[0].toUpperCase() : ''))
    .splice(0, 2)
    .join('');
}

export function getUploadData({ type, data }) {
  if (type === 'file') {
    const handleFile = value => {
      if (!value) return value;

      const separator = 'base64,';
      return value.split(separator)[1];
    };

    const { value } = data;
    const [file] = value;
    return handleFile(file.value);
  }

  return data;
}

export function getDownloadFiles({ files, invoice = null }) {
  const invoices =
    invoice && invoice.file_url ? [{ title: 'invoice.pdf', url: invoice.file_url }] : [];

  const paymentFiles = files
    .map(file => ({
      title: file.title,
      url: file.link
    }))
    .filter(file => file.url && AppUtils.isValidHttpUrl(file.url));
  return [...paymentFiles, ...invoices];
}

export function isValidXML(value) {
  const parser = new DOMParser();
  try {
    const xml = parser.parseFromString(value, 'text/xml');
    if (xml.getElementsByTagName('parsererror').length > 0) {
      console.error('XML parsing error:', xml.getElementsByTagName('parsererror')[0]);
      return false;
    }

    return true;
  } catch (e) {
    console.error('Error while parsing XML:', e);
    return false;
  }
}
