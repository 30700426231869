import React from 'react';
import PropTypes from 'prop-types';
import { Avatar } from 'mw-style-react';
import { getContrastColor, getInitials } from '../../../../../utils/helperFunctions';
import '../Workspaces.scss';

function WorkspacePreview({ data }) {
  return (
    <div styleName="Workspaces-preview">
      <div styleName="Workspaces-preview__image">
        <Avatar size="small" src={data.photo} bgColor={data.color}>
          {!data.photo && (
            <div style={{ color: getContrastColor(data.color) }}>{getInitials(data.name)}</div>
          )}
        </Avatar>
      </div>
      <div styleName="Workspaces-preview__name">{data.name}</div>
    </div>
  );
}

WorkspacePreview.propTypes = {
  data: PropTypes.object
};

export default WorkspacePreview;
