import { defineMessages } from 'react-intl';
import globalIntl from 'globalIntl'; // eslint-disable-line import/no-unresolved

const m = defineMessages({
  manageUserGroupsHeader: {
    id: 'manageUserGroupsHeader',
    defaultMessage: 'Manage groups'
  },
  manageUserGroupsButton: {
    id: 'manageUserGroupsButton',
    defaultMessage: 'Save'
  },
  manageUserGroupsEmptyList: {
    id: 'manageUserGroupsEmptyList',
    defaultMessage: 'There are no groups yet.'
  }
});

export default { ...globalIntl, ...m };
