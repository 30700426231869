/* eslint-disable max-len */
import Client from 'mw-fetch';
import { call, put, select } from 'redux-saga/effects';
import { SHOW_NOTIFY, NOTIFY_LEVEL, API_PATH } from 'constants';
import { Utils } from 'mw-style-react';
import AppUtils from '../utils/utils';
import history from '../store/history';

// Отображение ошибки
function* sendError(message) {
  yield put({
    type: SHOW_NOTIFY.REQUEST,
    payload: {
      id: Utils.random().toString(),
      type: NOTIFY_LEVEL.ERROR,
      label: message
    }
  });
}

/**
 * Вызов внутреннего апи
 */
const jsonClient = (headers = {}) =>
  new Client({
    credentials: 'include',
    headers: {
      Referer: window.location.href,
      Origin: window.location.origin,
      ...headers
    }
  });

const multipartClient = (headers = {}) =>
  new Client({
    headers: {
      'Content-Type': 'multipart/form-data',
      'Referer': window.location.href,
      'Origin': window.location.origin,
      ...headers
    }
  });

const urlencodedClient = (headers = {}) =>
  new Client({
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Referer': window.location.href,
      'Origin': window.location.origin,
      ...headers
    }
  });

function getConnector(reqType, headers) {
  switch (reqType) {
    case 'multipart':
      return multipartClient(headers);
    case 'urlencoded':
      return urlencodedClient(headers);
    default:
      return jsonClient(headers);
  }
}

export function customRedirectUrl(config) {
  const isSingleAuthProviderMode = config && config.single_auth_provider_mode;
  if (isSingleAuthProviderMode && config.auth_providers) {
    const confObj = config ? config.auth_providers[config.single_auth_provider_mode] : {};
    return confObj.path;
  }
}

// Ф-ция формирования url запроса
function makeUrl(url, queryParams) {
  return AppUtils.makeUrl(url, { ...queryParams });
}

function redirectToAuth(redirect) {
  const redirectUrl = encodeURIComponent(redirect || document.location.href);
  document.location.href = `${document.location.origin}/enter/login?redirect_uri=${redirectUrl}`;
}

export function redirectToCustomUrl(domain, redirect) {
  const redirectUrl = encodeURIComponent(redirect || document.location.href);
  document.location.href = `${document.location.origin}${domain}?redirect_uri=${redirectUrl}`;
}

function* api({
  method = 'get',
  url = '',
  body = {},
  reqType = 'json',
  queryParams = {},
  showError = true,
  skipRedirect = false,
  withApiPath = true,
  headers = {}
}) {
  if (!url) return;
  url = makeUrl(`${withApiPath ? API_PATH : ''}${url}`, queryParams);
  let response = {};
  try {
    // Вызов АПИ
    response = yield call([getConnector(reqType, headers), method], url, body);
    // Парсинг результатов от api
    switch (response.result) {
      case 'success':
        break;
      case 'error':
      case 'unauth':
        if (
          response.data &&
          (response.data.description || response.data.msg || response.data.message) &&
          (showError || response.data.code === 'Unauthorized') &&
          !skipRedirect
        ) {
          yield sendError(
            `${response.data.description || response.data.msg || response.data.message}${
              response.data.ref ? ` (REF: ${response.data.ref})` : ''
            }`
          );
        }
        if (response.data && response.data.code === 'Unauthorized' && !skipRedirect) {
          const config = yield select(state => state.config);
          const customUrl = customRedirectUrl(config);
          setTimeout(() => {
            if (customUrl) redirectToCustomUrl(customUrl);
            if (!customUrl) redirectToAuth();
          }, 500);
        }
        if (response.data && response.data.code === 'WorkspaceNotFound') {
          history().push(`/choose/no-permission`);
        }
        break;
      default:
        break;
    }
  } catch (e) {
    yield sendError(e.message);
  }

  return response;
}

export { api, makeUrl, redirectToAuth };
