import { defineMessages } from 'react-intl';
import globalIntl from 'globalIntl'; // eslint-disable-line import/no-unresolved

const m = defineMessages({
  unblockUser: {
    id: 'unblockUser',
    defaultMessage: 'Unblock user'
  },
  btnUnblockUser: {
    id: 'btnUnblockUser',
    defaultMessage: 'Unblock'
  }
});

export default { ...globalIntl, ...m };
