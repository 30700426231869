import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Modal, ModalButtons, ModalContent, Button, Upload, TextField } from 'mw-style-react';
import TextButton from '../../../TextButton';
import sModal from '../../Modal.scss'; // eslint-disable-line no-unused-vars
import './AddLicense.scss';
import { CHECK_USER_LINCESE, SET_USER_LINCESE } from '../../../../constants';
import Loader from '../../../Loader';
import { getUploadData } from '../../../../utils/helperFunctions';
import LicenseAttributes from '../../../LicenseAttributes';

const UPLOAD_TYPE = {
  FILE: 'File',
  TEXT: 'Text'
};

const FORM_VIEW = {
  FORM: 'Form',
  DIFF: 'Diff'
};

const INVALID_MESSAGE = 'The license you uploaded is invalid. Please try again.';

const TITLE = {
  REGULAR: 'Upload license',
  INVALID: 'Invalid license',
  COMPARE: 'Uploaded license'
};

function AddLicense({ visibility, onClose, dispatch, data }) {
  const [isSubmit, setSubmit] = useState(false);
  const [error, setError] = useState(false);
  const [tab, setTab] = useState(UPLOAD_TYPE.FILE);
  const [view, setView] = useState(FORM_VIEW.FORM);
  const [diffData, setDiffData] = useState(null);

  const { callback = () => {} } = data;

  const handleSubmit = ({ type, data }) => {
    setSubmit(true);
    const body = getUploadData({ type, data });

    dispatch({
      type: CHECK_USER_LINCESE.REQUEST,
      payload: {
        body: {
          data: body
        },
        callback: (result, data) => {
          if (result === 'error') {
            setError(true);
          } else {
            setDiffData(data);
            setView(FORM_VIEW.DIFF);
          }

          setSubmit(false);
        }
      }
    });
  };

  const handleApply = () => {
    if (!diffData.ref) return;

    setSubmit(true);

    dispatch({
      type: SET_USER_LINCESE.REQUEST,
      payload: {
        params: {
          ref: diffData.ref
        },
        callback: result => {
          if (result === 'error') {
            setError(true);
          } else {
            setDiffData(null);
            setView(FORM_VIEW.FORM);
            onClose();
            callback();
          }

          setSubmit(false);
        }
      }
    });
  };

  const handleChangeTab = nextTab => {
    if (tab === nextTab || isSubmit) return;
    setTab(nextTab);
  };
  const [textLicense, setTextLicense] = useState('');

  return (
    <Modal
      visibility={visibility}
      onClose={onClose}
      styleName={cn('sModal.modal__workspace', {
        large: view === FORM_VIEW.DIFF && !error
      })}
    >
      <div styleName="sModal.modal__header">
        {!error && (
          <div styleName="sModal.modal__header__close">
            <TextButton title="Cancel" onClick={onClose} />
          </div>
        )}
        <div styleName="sModal.modal__header__title">
          {error ? TITLE.INVALID : view === FORM_VIEW.FORM ? TITLE.REGULAR : TITLE.COMPARE}
        </div>
      </div>
      <div
        styleName={cn('sModal.modal__body', {
          'AddLicense-container': true,
          'is-diff': view === FORM_VIEW.DIFF && !error
        })}
      >
        <ModalContent styleName="sModal.modal__content">
          <div>
            {error && <div style={{ textAlign: 'center' }}>{INVALID_MESSAGE}</div>}

            {view === FORM_VIEW.FORM && (
              <div
                styleName="sModal.modal__description"
                style={{ marginBottom: tab === UPLOAD_TYPE.FILE && !error ? 0 : 20 }}
              >
                {!error && (
                  <div styleName="AddLicense">
                    <div styleName="AddLicense__tabs">
                      <div styleName="AddLicense__tabs-items">
                        <div
                          styleName={cn('AddLicense__tabs-item', {
                            'is-active': tab === UPLOAD_TYPE.TEXT,
                            'is-disabled': isSubmit
                          })}
                          onClick={() => handleChangeTab(UPLOAD_TYPE.TEXT)}
                        >
                          {UPLOAD_TYPE.TEXT}
                        </div>
                        <div
                          styleName={cn('AddLicense__tabs-item', {
                            'is-active': tab === UPLOAD_TYPE.FILE,
                            'is-disabled': isSubmit
                          })}
                          onClick={() => handleChangeTab(UPLOAD_TYPE.FILE)}
                        >
                          {UPLOAD_TYPE.FILE}
                        </div>
                      </div>
                    </div>

                    <div>
                      {tab === UPLOAD_TYPE.FILE ? (
                        <div styleName="AddLicense__input">
                          <Upload
                            id="AddLicense"
                            browseLinkLabel="browseLinkLabel"
                            preview={false}
                            viewType="default"
                            visibility={isSubmit ? 'disabled' : 'visible'}
                            styleName="AddLicense__input-upload"
                            error={false}
                            multiple={false}
                            onChange={data => handleSubmit({ type: 'file', data })}
                          >
                            <div styleName="AddLicense__preview">
                              {!isSubmit && (
                                <>
                                  <div styleName="AddLicense__preview-title">
                                    Drag and drop your license here
                                  </div>
                                  <Button
                                    styleName="AddLicense__preview-button"
                                    label="Browse file"
                                    size="large"
                                  />
                                </>
                              )}
                              {isSubmit && <Loader />}
                            </div>
                          </Upload>
                        </div>
                      ) : null}
                      {tab === UPLOAD_TYPE.TEXT ? (
                        <div styleName="AddLicense__textarea">
                          <TextField
                            size="large"
                            value={textLicense}
                            placeholder="Insert license..."
                            autoFocus={true}
                            autoSelect={false}
                            multiline
                            multilineType="text"
                            bordered
                            id="textLicense"
                            styleName="AddLicense__textarea-input"
                            visibility={isSubmit ? 'disabled' : 'visible'}
                            onChange={e => setTextLicense(e.value)}
                          />
                          {isSubmit && (
                            <div styleName="AddLicense__textarea-loader">
                              <Loader />
                            </div>
                          )}
                        </div>
                      ) : null}
                    </div>
                  </div>
                )}
              </div>
            )}
            {view === FORM_VIEW.DIFF && (
              <div styleName="AddLicense__diff">
                {!error && <LicenseAttributes data={diffData} />}
              </div>
            )}
          </div>
        </ModalContent>

        <ModalButtons styleName="sModal.modal__buttons">
          {!error && tab === UPLOAD_TYPE.TEXT && view !== FORM_VIEW.DIFF && (
            <Button
              label="Upload"
              size="medium"
              onClick={() => handleSubmit({ type: 'text', data: textLicense })}
              styleName="sModal.modal__btn sModal.wide"
              visibility={isSubmit || !textLicense ? 'disabled' : 'visible'}
              disabled={isSubmit || !textLicense}
            />
          )}

          {!error && view === FORM_VIEW.DIFF && (
            <Button
              label="Accept"
              size="medium"
              onClick={handleApply}
              styleName="sModal.modal__btn sModal.wide"
              visibility={isSubmit ? 'disabled' : 'visible'}
              disabled={isSubmit}
            />
          )}

          {error && (
            <Button
              label="Cancel"
              size="medium"
              type="error"
              onClick={onClose}
              styleName="sModal.modal__btn sModal.wide"
              visibility="visible"
            />
          )}
        </ModalButtons>
      </div>
    </Modal>
  );
}

AddLicense.propTypes = {
  visibility: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  data: PropTypes.object,
  dispatch: PropTypes.func
};

export default AddLicense;
