import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalButtons, ModalContent, Button } from 'mw-style-react';
import { GET_EXTRA_STAGE_CHANGES } from 'constants';
import cn from 'classnames';
import TextButton from '../../../TextButton';
import sModal from '../../Modal.scss'; // eslint-disable-line no-unused-vars
import './ChangeLicenseStateChanges.styles.scss';
import { formatNumberWithWhitespaces, isNumber } from '../../../../utils/helperFunctions';

const COUNTER = {
  MIN: 1,
  MAX: null,
  INIT: 1,
  STEP: 1
};

function ChangeLicenseStateChanges({ visibility, onClose, data, dispatch }) {
  const [isSubmit, setSubmit] = useState(false);
  const [count, setCount] = useState(data.selectedValue || COUNTER.INIT);
  const pricePerMillion = Number(data.price);
  const totalPrice = pricePerMillion * count;

  const handleSubmit = () => {
    setSubmit(true);
    dispatch({
      type: GET_EXTRA_STAGE_CHANGES.REQUEST,
      payload: {
        params: {
          licenseId: data.licenseId
        },
        body: {
          amount: totalPrice,
          count
        },
        callback: result => {
          if (result === 'error') {
            setSubmit(false);
          } else {
            onClose();
          }
        }
      }
    });
  };

  const onChangeCount = type => {
    if (type === 'decrease') {
      if (count === COUNTER.MIN) return;
      setCount(count - COUNTER.STEP);
    } else {
      if (count === COUNTER.MAX) return;
      setCount(count + COUNTER.STEP);
    }
  };

  useEffect(() => {
    if (!data || !isNumber(pricePerMillion)) {
      onClose();
    }
  }, [data, pricePerMillion, onClose]);

  return (
    <Modal
      visibility={visibility}
      onClose={onClose}
      styleName="sModal.modal__workspace"
      size="small"
    >
      <div styleName="sModal.modal__header">
        <div styleName="sModal.modal__header__close">
          <TextButton title="Cancel" onClick={onClose} />
        </div>
        <div styleName="sModal.modal__header__title">Select state changes</div>
      </div>
      <div styleName="sModal.modal__body" style={{ overflow: 'auto' }}>
        <ModalContent styleName="sModal.modal__content">
          <div>
            <div styleName="sModal.modal__description">
              <div styleName="change-state-changes">
                <div styleName="change-state-changes__description">
                  Specify the needed number of state changes
                </div>
                <div styleName="change-state-changes__counter">
                  <div
                    styleName={cn('change-state-changes__counter-button', {
                      'is-disabled': count === COUNTER.MIN || isSubmit
                    })}
                    onClick={() => onChangeCount('decrease')}
                  >
                    –
                  </div>
                  <div styleName="change-state-changes__counter-value">{count}M</div>
                  <div
                    styleName={cn('change-state-changes__counter-button', {
                      'is-disabled': count === COUNTER.MAX || isSubmit
                    })}
                    onClick={() => onChangeCount('increase')}
                  >
                    +
                  </div>
                </div>
                <div styleName="change-state-changes__total">
                  <div styleName="change-state-changes__total-row">
                    <div styleName="change-state-changes__total-cell">
                      Price per 1M state changes
                    </div>
                    <div styleName="change-state-changes__total-cell">
                      ${formatNumberWithWhitespaces(pricePerMillion)}
                    </div>
                  </div>
                  <div styleName="change-state-changes__total-row">
                    <div styleName="change-state-changes__total-cell">Total</div>
                    <div styleName="change-state-changes__total-cell">
                      ${formatNumberWithWhitespaces(totalPrice)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ModalContent>
        <ModalButtons styleName="sModal.modal__buttons">
          <Button
            label="Buy state changes"
            size="medium"
            onClick={handleSubmit}
            styleName={cn('sModal.modal__btn', 'sModal.wide')}
            visibility={isSubmit ? 'disabled' : 'visible'}
            disabled={isSubmit}
          />
        </ModalButtons>
      </div>
    </Modal>
  );
}

ChangeLicenseStateChanges.propTypes = {
  visibility: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  data: PropTypes.object,
  dispatch: PropTypes.func
};

export default ChangeLicenseStateChanges;
