import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

const Context = React.createContext('light');

function IntlProviderWrapper({ children, locale, messages }) {
  const contextValue = useMemo(() => ({ locale, messages }), [locale, messages]);
  return <Context.Provider value={contextValue}>{children}</Context.Provider>;
}

IntlProviderWrapper.defaultProps = {
  messages: {}
};

IntlProviderWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  locale: PropTypes.string.isRequired,
  messages: PropTypes.object
};

export { IntlProviderWrapper, Context as IntlContext };
